import { Alert } from '@chakra-ui/react';
import axios from 'axios';
import { server,tag_name } from '../../../config';
import Swal from 'sweetalert2'
async function CreateProductInfo(batchId,inputList){
   let data=[{
        inputList
    }]
    let assetId = tag_name + batchId;

    const rdata = await axios
        .post(`${server}/api/blockchaindata/add`, {
          assetId,
          data
        }
        )
        .then((res) => {
          if(res.status==200){
          return true;
          }
          else  if(res.status==203){
            return false;
          }
        })
        .catch((err) =>
        alert("Error updating product")
        // Swal.fire("Error Adding This Id/Already Exists",'error')    
        );
      const product = await rdata;
      console.log(product);
    //   return product;
    }


export default CreateProductInfo;