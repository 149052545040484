import { Button, Flex,Text } from "@chakra-ui/react";
import Card from 'components/Card/Card';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import { server } from "config";
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css'
import pagenationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { dateFilter, Comparator, textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Swal from 'sweetalert2'
const CustomerHistoryList = () => {
  const [location, setLocation] = useState([]);
  const logData = localStorage.getItem('tg-token');
  let logDetails = JSON.parse(logData);
  const pagination = pagenationFactory({
    page: 1,
    sizePerPage: 5,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    showTotal: true,
   
  });


  useEffect(async () => {
    let data = getLocation();

  }, [])
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <Flex>
        <Button m={5} class='bn632-hover bn26' onClick={handleClick} >Export to CSV</Button>
      </Flex>
    );
  };
  async function getLocation() {
    let data = await axios
      .get(`${server}/api/location/`, {
        timeout: 5000,
      })
      .then((res) => {
        setLocation(res.data);
        return res.data;

      })
      .catch((err) => console.error(err));
    return data;
  }
  // function rankFormatter(cell, row, rowIndex, formatExtraData) {
  //   return (
  //     <i className={formatExtraData[cell]} onClick={onDelete} />
  //   );
  // }

  function dateFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>

        {filterElement}
        {column.text}
      </div>
    );
  }
  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      sort:true,
      
      // headerAlign: 'center',
      filter: dateFilter({
        delay: 200,
        // placeholder:'empty',
        withoutEmptyComparatorOption: true,
        comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
        style: {display:'flex',direction:'row'},
        // className: 'custom-datefilter-class',
        comparatorStyle: { backgroundColor: 'transparent',alignItems:'center',borderColor:'#36311F',margin:'6px',fontWeight:'bolder'},
        // comparatorClassName: 'custom-comparator-class',
        dateStyle: { backgroundColor: 'transparent', width: "30%",borderColor:'#36311F',paddingRight:'1px',paddingLeft:'1px',margin:"6px"},
       
        // dateClassName: 'custom-date-class'
      }),
     
      style: {
        // textAlign: 'center',
        color: 'black',
        width: '850px'
      }, 
      headerFormatter: dateFormatter,
      formatter: (cell, row) => (
          <>
           {
            row.date.slice(8, 10) + '/' 
            + row.date.slice(5, 7) + '/' 
            + row.date.slice(0, 4)}
          </>
      ),
    },
    {
      dataField: 'productId',
      text: 'Id',
      sort: true,
      headerAlign: 'center',
      filter: textFilter({
        style: {
          backgroundColor: '#36311F',
          borderColor: '#36311F',
          marginTop:"5px",
          
        },
        // className: 'test-classname',
        placeholder: 'Enter Id',
        }),
      headerFormatter: dateFormatter,
      style: {
        textAlign: 'center',
        color: 'black',
        width:'100px'
      }
    },
    {
      dataField: 'productName',
      text: 'Name',
      sort: true,
      headerAlign: 'center',
      attrs: { title: `productName` },
      style: {
        width: '300px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'black'
      }
      // filter: textFilter()
    },
    {
      dataField: 'region',
      text: 'Region',
      headerAlign: 'center',
      style: {
        width: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'black'
      }
    },
    {
      dataField: 'subRegion',
      text: 'Sub Region',
      headerAlign: 'center',
      style: {
        width: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'black'
      }
    },
    {
      dataField: 'cityCountry',
      text: 'Place',
      headerAlign: 'center',
      style: {
        width: '150px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'black'
      }
    
    }
  ]

  const onDelete = async (id) => {
    const res = await fetch(`${server}/api/location/delete/${id}`, {
      // headers: { 'x-access-token': logDetails.token},
      method: 'DELETE',

    });

    const deleted = () => {
      Swal.fire(
        'Location Deleted Successfully!',
        'success'
      )
      window.location.pathname="/admin/history"
      
      // alert('Product Deleted Successfully');
    };
    res.status === 200 ? deleted() : alert('Error Deleting This Product');
  };
  const defaultSorted = [{
    dataField: 'date',
    order: 'desc'
  }];
  return (
    <Flex>
      <Card>
      <Text
                    color={'white'}
                    textShadow={'0px 4px 8px rgba(0, 0, 0, 0.5)'}
                    letterSpacing={1}
                    fontSize='3xl'
                    textTransform={'uppercase'}
                    fontWeight='extrabold'
                    mb={10} > Customer Location  </Text>
        <Flex>
          {location.length>0  ? <ToolkitProvider
            keyField="id"
            bootstrap4
            data={location}
            columns={columns}
            exportCSV={{ onlyExportFiltered: true, exportAll: false}}
          >
            {
              props => (
                <div>
                  {/* <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton>
        <hr /> */}
                  <MyExportCSV {...props.csvProps} />
                  <hr />
                  <BootstrapTable {...props.baseProps}
                    filter={filterFactory()} headerClasses="header-class" filterPosition="bottom" pagination={pagination}   defaultSorted={ defaultSorted } />
                </div>
              )
            }
          </ToolkitProvider> : "Location List is Empty"}
        </Flex>
      </Card>
    </Flex>
  );
}

export default CustomerHistoryList;