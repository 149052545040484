import React,{useEffect} from 'react'
import { Flex, Image, AspectRatio } from '@chakra-ui/react'
import ParticleAnime from './3dParticleAnime'
import './Header3d.scss'
// import HeadImg from '../../../assets/images/NewBrew/Blockchain-Reaction_round.png'

let Pimg = "https://res.cloudinary.com/dj6eghdzu/image/upload/v1652711767/Brewtoon/SmallBatch_Blockchain-Reaction_round_mto5fq.png"

  
function Header() {
  
useEffect(()=>{
  const AnimePlay = ()=>{
    ParticleAnime()
  }
  AnimePlay()
},[])

  return (
  <Flex  w="100%" h="430px" className='et_pb_section_0'  display={{lg:'none',xl:"none"}}  >
    <Flex  justifyContent={"center"}>

   <Flex  w="300px" h="300px" mt={"20"} borderRadius="50%"  opacity={"0.8"} alignItems="center" position="absolute" justifyContent={"center"} bgColor="#ffffff00" className='headMain '>
     <Flex className='animate__animated animate__zoomIn animate__delay'>

     <Image src={Pimg} alignItems="center" opacity={"0.9"} filter={"contrast(1.6)"}/>
     </Flex>
   </Flex>

    </Flex>
  </Flex>
  )
}

export default Header