import React, { useState, useEffect } from "react";
import logo from "../../../../assets/new/1.png";
import { Flex, Image, Text,TableCaption } from "@chakra-ui/react";
import Card from "components/Card/Card";
import { tag_name } from "../../../../config";
import ReadProductInfo from "components/CommonFunctions/DBCalls/ReadBlockchainData";
export default function ProductBLockchain(prop) {
  let [blockchainData, setBlockchainData] = useState([]);
  var prodata = "";
  let assetId = prop.assetId;
  useEffect(async () => {
    prodata = await ReadProductInfo(assetId);

    if (prodata.data) {
      (prodata.data).forEach(element => {
        if(element.assetId === 'BrewToontest4')
        {
          setBlockchainData(element.data);
        }
      });
     
    } else {
      console.log("No Data Avilable");
    }
  }, []);

  return (
    <>
      {" "}
      {blockchainData.length > 0 ? (
        <Flex display={{ lg: "none", xl: "none" }}>
          <Flex direction={"column"} mt={8} >
            <Flex pl={5}>
              <Image src={logo} alt="Process" />
              <Text
                fontWeight="700"
                fontSize="30px"
                lineHeight="133.69%"
                textTransform="uppercase"
                className="custom-font-heading "
                data-aos="fade-down"
                data-aos-delay="300"
                data-aos-duration="300"
                data-aos-easing="linear"
              >
                Product Info
              </Text>
            </Flex>
            {blockchainData &&
              blockchainData.map((data, i) => (
                <>
               {i==1 ?<div  data-aos="fade-right"
                          data-aos-easing="ease-in-sine"
                          data-aos-delay="300">  <Text color="black" textAlign={'left'} ml='20px' mt={'10px'}  fontSize={'18px'}  className="custom-font-heading " fontWeight={'bold'}>INGREDIENTS TRACEABILITY</Text>
                <Text color="black" textAlign={'left'} ml="20px" mt="2px" fontSize={'14px'}  className="custom-font-description " fontWeight={'bold'}>MALTS (Origin: Muntons, UK) </Text>
                <Text color="black" textAlign={'left'} ml='20px' fontSize={'12px'}  className="custom-font-description " >Batch Details</Text>
                
                </div>:""}
                  {i==2 ?<div  data-aos="fade-right"
                          data-aos-easing="ease-in-sine"
                          data-aos-delay="300">  <Text color="black" mt="8px" textAlign={'left'} ml="20px" fontSize={'14px'}  className="custom-font-description " fontWeight={'bold'}>HOPS (Origin: Yakima Chief, USA) </Text>
                <Text color="black" textAlign={'left'} ml='20px' fontSize={'12px'}  className="custom-font-description " >Batch Details</Text></div>:""}
                {i==3 ?<div  data-aos="fade-right"
                          data-aos-easing="ease-in-sine"
                          data-aos-delay="300">  <Text color="black" mt="8px" textAlign={'left'} ml="20px" fontSize={'14px'}  className="custom-font-description " fontWeight={'bold'}>YEAST (Origin: WHC Lab, Newcastle, UK) </Text>
                <Text color="black" textAlign={'left'} ml='20px' fontSize={'12px'}  className="custom-font-description " >Batch Details</Text></div>:""}
                {i==4 ?<div  data-aos="fade-right"
                          data-aos-easing="ease-in-sine"
                          data-aos-delay="300">  <Text color="black" mt="8px" textAlign={'left'} ml="20px" fontSize={'14px'}  className="custom-font-description " fontWeight={'bold'}>WATER (Origin: Scottish Water, UK )</Text>
                <Text color="black" textAlign={'left'} ml='20px' fontSize={'12px'}  className="custom-font-description " >Batch Details</Text></div>:""} 
                  {data.inputList.map((list, i) => (
                    <>
                   
                       <Flex direction={"row"} mt={1} justifyContent={"center"}>
                        <Card
                          background="#0092B9"
                          boxShadow="inset 0px 4px 4px rgba(0, 0, 0, 0.29)"
                          borderRadius="6px"
                          width="40%"
                          p={2}
                          className="custom-font-description"
                          data-aos="fade-right"
                          data-aos-easing="ease-in-sine"
                          data-aos-delay="300"
                        >
                          {list.parameterName}
                        </Card>
                        <Card
                          background="linear-gradient(90.12deg, rgba(2, 148, 187, 0.748841) 8.92%, rgba(0, 146, 185, 0.78) 15.63%, rgba(34, 171, 208, 0.3198) 105.45%)"
                          boxShadow="inset 0px 4px 4px rgba(0, 0, 0, 0.29)"
                          borderRadius="6px"
                          width="48%"
                          p={2}
                          ml={2}
                          className="custom-font-product-description"
                          data-aos="fade-right"

                          data-aos-easing="ease-in-sine"
                          data-aos-delay="600"
                        >
                          {list.value}
                        </Card>
                      </Flex>
                    </>
                  ))} 
                </>
              ))}
            <Text
              alignSelf={"flex-end"}
              mr={25}
              mt={1}
              className="custom-font-description"
              data-aos="zoom-in"
              data-aos-delay="800"
            >
              <b className="me-1" color="black">
                BLOCKCHAIN
              </b>
              <span color="gray"> Powered</span>
            </Text>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
}
