// import
import { SiHiveBlockchain,SiGoogleanalytics } from "react-icons/si";
import {MdListAlt,MdPrint,MdHistoryEdu,MdLocationOn } from "react-icons/md";
import {
  HomeIcon,
  StatsIcon,
} from "./components/Icons/Icons";

var dashRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: <HomeIcon color="inherit" />,
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  // {
  //   path: "/productList",
  //   name: "Product List",
  //   icon: <MdListAlt  color="inherit" />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/trackOrigin",
  //   name: "Track Origin",
  //   icon: <StatsIcon color="inherit" />,
  //   component: Tables,
  //   layout: "/admin",
  // },
  {
    path: "/bcTrack",
    name: "Blockchain Data",
    icon: <SiHiveBlockchain color="inherit" />,
    layout: "/admin",
  },
  {
    path: "/analysis",
    name: "Product Analysis",
    icon: <SiGoogleanalytics color="inherit" />,
    layout: "/admin",
  },
  // {
  //   path: "/trackProduct",
  //   name: "Track Product",
  //   icon: <StatsIcon color="inherit" />,
  //   component: Tables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/printQR",
  //   name: "Print QR",
  //   icon: <MdPrint color="inherit" />,
  //   layout: "/admin",
  // },
  {
    path: "/history",
    name: "Customer Clicks",
    icon: <MdHistoryEdu color="inherit" />,
    layout: "/admin",
  },
  // {
  //   path: "/latesthistory",
  //   name: "Customer Location",
  //   icon: <MdLocationOn color="inherit" />,
  //   layout: "/admin",
  // },
  
];
export default dashRoutes;
