import { gsap, SlowMo, TextPlugin } from 'gsap/all';
import React, { useEffect, useRef } from 'react';

const Intro = () => {
  //register plugins with GSAP
  gsap.registerPlugin(TextPlugin, SlowMo);
  const ref = useRef(null);

  useEffect(() => {
    let isMounted = true;
    const animate = () => {
      if (isMounted) introAnimation();
    };
    animate();
    return () => {
      isMounted = false;
    };
  }, []);
  function introAnimation() {
    const elements = ref.current;
    let word1 = elements.querySelector('#word1');
    let word2 = elements.querySelector('#word2');
    let word3 = elements.querySelector('#word3');
    let word4 = elements.querySelector('#word4');
    let motto = elements.querySelector('#sentence');
    var tl = gsap.timeline({ delay: 3.5 }),
      words = [word1, word2, word3, word4],
      element = word1,
      time = 0,
      duration,
      i;

    for (i = 0; i < words.length; i++) {
      element = words[i];
      duration = Math.max(0.5, 5 * 0.08);
      gsap.set(element, { autoAlpha: 0, scale: 0, z: 0.01 });

      tl.to(
        element,
        { duration, scale: 1.2, ease: 'slow(0.25, 0.9)' },
        time
      ).to(
        element,
        {
          duration,
          autoAlpha: 1,
          ease: 'slow(0.25, 0.9, true)',
        },
        time
      );
      time += duration - 0.05;
    }

    gsap.set(motto, { autoAlpha: 0, scale: 0, z: 0.01 });

    gsap
      .timeline({ delay: 3.5 })
      .to(motto, { duration, scale: 1.2, ease: 'slow(0.25, 0.9)' }, time)
      .to(
        motto,
        {
          duration: 2.9,
          autoAlpha: 1,
          ease: 'slow(0.25, 0.9, true)',
        },
        time
      );
  }

  return (
    <section
      id="intro"
      className="min-vh-100 bg-dark content-center position-relative"
      ref={ref}
    >
      <div className="intro__logo position-absolute animate__animated animate__rotateIn">
        <img
          src="./logo.png"
          alt="logo"
          className="animate__animated animate__rotateOut animate__delay-2s"
        />
      </div>
      {/* Need intro here  */}
      <div className="animate__intro__text">
        <h2 id="word1" className="text-white">
          Naut
        </h2>
        <h2 id="word2" className="text-white">
          Your
        </h2>
        <h2 id="word3" className="text-white">
          Average
        </h2>
        <h2 id="word4" className="text-white">
          Brew
        </h2>
        <h3 id="sentence" className="text-white">
          Naut Your Average
          <br />
          <span className="main__text">Brew</span>
        </h3>
      </div>
    </section>
  );
};

export default Intro;
