import React, { useState, useRef } from "react";
import ReactPlayer from 'react-player/youtube'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  Text
} from '@chakra-ui/react';

import { AiTwotoneFire, AiOutlineCloseCircle } from "react-icons/ai";
import { FaBookReader } from "react-icons/fa";
import './video.css'
const Video = (prop) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
// console.log(data);
  const btnRef = useRef()
  let link = prop.link;
  let description = prop.description;
  let heading = prop.heading
  // console.log(heading,"headinggggg")
  // console.log(link);
  return (
    <>
             
      <Button
      px={6}
      mt={4}
        justifyContent={"center"}
        alignSelf={"center"}
        cursor="pointer"
        // background="linear-gradient(90.68deg, #ECFBC2 18.65%, rgba(206, 234, 231, 0.812257) 56.32%, rgba(255, 255, 255, 0.510015) 90.4%, rgba(246, 218, 184, 0.46) 99.81%)"
        // box-shadow="inset 0px 4px 4px rgba(0, 0, 0, 0.29)"
        // borderRadius="15px"
        class="btn-grad"
        textAlign={"center"}
        pl={"2px"}
        ml="20px"
        // alignSelf={"center"}
        pr={2}
        // boxShadow=" inset 0px 2px 4px rgba(0, 0, 0, 0.4)"
        height='30px'
        width={'90px'}
        color={"#000"}
        // color='white'
        fontWeight={"semibold"}
        // textDecoration={"underline"}
        borderBottom={"1px solid blue"}
        fontSize={'14px'}
        ref={btnRef}
     
        onClick={() => {
          onOpen();
        }}
      >
        <Text fontWeight="700"
          fontSize="12px"
         textAlign={'center'}
          lineHeight="50%"
          textTransform=" uppercase">Watch Video </Text>
      </Button>
      <Modal
        onClose={onClose}
        finalFocusRef={btnRef}
        isOpen={isOpen}
        scrollBehavior='inside'
        isCentered
        allowPinchZoom={true}
      // size={'lg'}
      overflow={'auto'} 
      >
        <ModalOverlay bg="blackAlpha.300"
          backdropFilter="auto"
          backdropInvert="25%"
          backdropBlur="4px" />
        <ModalContent mx={10} justifyContent={'center'} bg={"transparent"} >
          <Button
            backgroundColor={"black"}
            boxShadow="0px 4px 12px 2px rgba(0, 0, 0, 0.5)"
            p={0}
            mb={3}
            color={"white"}
            borderRadius={100}
            alignSelf={"end"}
            size={"md"}
            // id="closeBut"
            // position={'absolute'}
            onClick={onClose}
          >
            <AiOutlineCloseCircle size={25} />
          </Button>
          {/* <ModalHeader> */}

            <Flex className="video" borderBottomLeftRadius={10} borderBottomRightRadius={10}  >
              <ReactPlayer url={link} controls={true} loop={true} playing={true} width={"100%"} style={{zIndex:"2"}} height="100%"/>
              {/* test url: https://youtu.be/m2iCiUrR4Wk */}
            </Flex>
          {/* </ModalHeader> */}
          {/* <ModalBody className="overflow-auto" bg={'white'}   borderBottomLeftRadius={10} borderBottomRightRadius={10}>
            <Text textAlign={"left"} pl={20} fontWeight={"bold"} fontSize={"30px"} >{heading}</Text>
            <Flex justifySelf={'center'} style={{ textIndent: "15px" }} fontSize={18} m={15} textAlign={'justify'} padding={5}>
            {description}
            </Flex>
          </ModalBody> */}

        </ModalContent>
      </Modal>
    </>
  )
}
export default Video;