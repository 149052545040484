
// Chakra Imports
import {
  Button,
  Flex,
  Box,
  Text,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  Portal,
  PopoverCloseButton,
} from "@chakra-ui/react";


// Custom Icons
import { ProfileIcon, SettingsIcon } from "../../components/Icons/Icons";
// Custom Components
import { uri } from "config";
import { SidebarResponsive } from "../../components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import routes from "../../routes.js";
import ChangePassword from "../../views/Dashboard/PasswordChange";

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const logData = localStorage.getItem('tg-token');
  let logDetails = JSON.parse(logData);
  // Chakra Color Mode
  let mainTeal = useColorModeValue("teal.300", "teal.300");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  // let searchIcon = useColorModeValue("gray.700", "gray.200");
  const handleLogout = () => {
    window.localStorage.removeItem('tg-token');
    window.location.pathname = `/`;
  }
  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <Popover>
        <PopoverTrigger>
          <Button
            ms="0px"
            px="0px"
            me={{ sm: "2px", md: "16px" }}
            color={navbarIcon}
            bgColor={"whiteAlpha.500"}
            borderRadius={15}
            shadow='0px 4px 8px rgba(0, 0, 0, 0.5)'
            place
            p={4}
            variant="transparent-with-icon"
            rightIcon={
              document.documentElement.dir ? (
                ""
              ) : (
                <ProfileIcon color={navbarIcon} w="30px" h="25px" me="0px" />
              )
            }
            leftIcon={
              document.documentElement.dir ? (
                <ProfileIcon color={'black'} w="30px" h="25px" me="0px" />
              ) : (
                ""
              )
            }
          >
           <Text display={{ sm: "none", md: "flex" }} fontSize={18} fontWeight={'bold'} color={'blackAlpha.600'} > {logDetails ? logDetails.userName : "User"}</Text>
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w={{ base: '45', md: '30' }} bg={'whiteAlpha.500'} borderRadius={'10'}>
            <PopoverArrow bg={'black'} />
            {/* <PopoverCloseButton color={'red'}/> */}
            <PopoverBody bg={'black'} borderRadius={'10'}>
              <Flex flexDirection="column">
              <ChangePassword />
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  mt="24px"
                >
                  <NavLink to="/">
                    <Button onClick={handleLogout} bg={'white'} boxShadow='0px 4px 8px rgba(0, 0, 0, 0.5)'>
                      Log Out
                    </Button>
                  </NavLink>
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
      {/* <SettingsIcon
        cursor="pointer"
        ms={{ base: "16px", xl: "0px" }}
        me="16px"
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      /> */}

    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
