import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes
} from 'react-router-dom';

import AdminLayout from "./layouts/Admin.js";
import Dashboard from "./views/Dashboard/Dashboard.js";
import MasterFile from "./components/ProductDetails/MasterFile.js";
import ProductList from "./views/Dashboard/ProductList.js";
import TrackList from "./views/Dashboard/TrackList.js";
import BcTrack from "./views/Dashboard/BcTrack.js";
import TrackOrigin from "components/ProductDetails/TrackOrigin.js";
import TrackProduct from "components/ProductDetails/TrackProduct";
import MainPage from "./views/MobileUI/MainPage";
import PrintQR from "views/Dashboard/PrintQR.js";
// import Header from './components/MainPage/Header/Header'
import Video from './components/MainPage/VideoPopUp/Videos';
// import LatestLocation from "components/ProductDetails/LatestLocation"
import CustomerHistoryList from './components/ProductDetails/CustomerHistoryList';
import AgeTracker from './views/Dashboard/AgeTracker';
import ChangePassword from "./views/Dashboard/PasswordChange";
import './App.css';
import Login from "views/Pages/SignIn";
import './App.scss';
import 'animate.css';
import NotFound from './components/NotFound';
import BreweryInfo from "components/MainPage/newPage/BreweryInfo/BreweryInfo.js";



import Description from './components/MainPage/newPage/Description/Description';

// testRoute
import Header from './components/MainPage/NewHeader/Header'
import ProductAnalysis from "views/Dashboard/ProductAnalysis.js";

function App() {
  const logData = localStorage.getItem('tg-token');
  return (
    <BrowserRouter>
      <Routes>
        <Route path='product' element={<MainPage />} />       
        <Route path="age" element={<AgeTracker />}/> 

        <Route path="header" element={<Header />}/> 
        <Route path="description" element={<Description />}/> 

        <Route path="brewery" element={<BreweryInfo/>}/>
       
        {logData ?
          <>
            (<Route index element={<Navigate replace to= 'admin/dashboard' />} />)
            <Route path="admin" element={<AdminLayout />}>
              <Route index element={<Navigate replace to="/admin/dashboard" />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="analysis" element={<ProductAnalysis />}/>
              <Route path="addProduct" element={<MasterFile />} />
              <Route path="addProduct/:id" element={<MasterFile />} />
              <Route path="productList" element={<ProductList />} />
              <Route path="trackList" element={<TrackList />} />
              <Route path="trackOrigin" element={<TrackOrigin />} />
              <Route path="trackProduct" element={<TrackProduct />} />
              <Route path="bcTrack" element={<BcTrack />} />
              <Route path="printQR" element={<PrintQR />} />
              <Route path="history" element={<CustomerHistoryList />} />
           <Route path='changepassword' element={<ChangePassword />} />
              {/* <Route path="latesthistory" element={<LatestLocation />} /> */}
            </Route>
            <Route path="/" element={<Navigate replace to="/admin/dashboard" />}/>
          </>
          : 
          <>(<Route index element={<Login />} />)
          <Route index element={<Navigate
            replace
            to={logData ? 'admin/dashboard' : '/'}
          />} />
          </>
          }
          
      </Routes>
    </BrowserRouter>
  );
}

export default App;
