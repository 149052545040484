/** @format */

import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import logo from "../../../../assets/new/1.png";
import lighten from "../../../../assets/new/lightening2.gif";
import flower from "../../../../assets/new/3.png";
import hatman from "../../../../assets/new/blockchain.png";
import "../BreweryInfo/brewer.css";
export default function BreweryInfo() {
  return (
    <Flex display={{ xl: "none", lg: "none", '2xl': "none", md: "none" }}>
      <Flex direction={"column"} mt={8} >
        <Flex pl={5}>
          <Image src={logo} alt="Process" />
          <Text
            fontWeight="700"
            fontSize="30px"
            lineHeight="133.69%"
            textTransform="uppercase"
            className='custom-font-heading'
            data-aos="fade-down"
            data-aos-delay="300"
            data-aos-duration="300"
            data-aos-easing="linear"
          >
           Sustainability
          </Text>
        </Flex>
        <Flex
          direction={"column"}
          width={"90%"}
          height="100%"
          alignSelf={"center"}
          mt={6}
          position="relative"
        >
          <Flex height={"10%"} borderRadius="10px 10px 0px 0px">
            <Image
              borderRadius="10px 10px 0px 0px"
              height={"50px"}
              width={"100%"}
              src={lighten}
              alt="minal"
            />
          </Flex>
          <span className="dot" id="dot1"></span>
          <span className="dot" id="dot2">
            <Image
              alignSelf={"center"}
              src={hatman}
              ml={9}
              mt={2}
              alt="thoppykaran"
              width={"40%"}
              hight={"40%"}
              className="brewAnime2"
            />
          </span>
          <Flex
            height={"80%"}
            p={6}
            direction={"column"}
            pt={16}
            background={`linear-gradient(180deg, rgba(17, 202, 255, 0.2) 59.79%, rgba(255, 255, 255, 0.2) 92.77%), url(${flower})`}
            bgRepeat="no-repeat"
            bgSize={"cover"}
          >
            <Text
              fontSize="22px"
              fontWeight="500"
              lineHeight="30px"
              className="custom-font-description"
              textAlign="justify"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="2000"
            >
              TrackGenesis is an award-winning blockchain based software company
              headquartered in Aberdeen, United Kingdom. We aim to support the
              businesses to grow by enabling them to transform digitally through
              cutting edge technological solutions using blockchain and other
              modern software technologies.
            </Text>
          </Flex>
          <Flex height={"10%"}>
            <Image height={"30px"} width={"100%"} src={lighten} alt="minal" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
