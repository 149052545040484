import React, { useRef, useState, useEffect } from 'react';
import { AiFillPrinter } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import QR from '../../components/QRCode/QR';
import axios from 'axios';
import { server, uri } from '../../config';
import {
  Flex,
  Button,
  Input,
  FormLabel,
  FormControl,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";
import Card from 'components/Card/Card';
import Swal from 'sweetalert2';
const PrintQR = () => {
  const [batchId, setBatchId] = useState('');
  const [productId, setProductId] = useState('');
  const [product, setProduct] = useState([]);
  const [dataUrl,setDataUrl]=useState('')
  const [status,setStatus] =useState(false)
  const componentRef = useRef();
  useEffect(() => {
    const datas = axios
      .get(`${server}/api/products/`, {
        timeout: 5000,
      })
      .then((res) => {
        setProduct(res.data)
        return res.data;
      })
      .catch((err) => console.error(err));
    return datas;
  }, [])
  // add after print function to loop
  // also add print error function to avoid crashing

  const downloadQRCode = async() => {
    setStatus(true);
    if(productId && batchId){
    if(dataUrl){
    let aEl = document.createElement("a");
    aEl.href =  dataUrl;
    aEl.download = `${batchId}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
    }
    }
    else{
      Swal.fire(
        'Error :Add Product Id and Batch Id'
      )
    }
  }
  return (
    <Flex>
      <Card  >
          <Flex  direction={{ base: 'column', md: 'row' }}
                          w="100%"
                          background="transparent"
                          p={{ base: '8px', md: '28px', xl: '0' }} >
            <FormControl variant='floating' id='batchId'  mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired>
              <Input
                type="text"
                id="batchId"
                placeholder="Batch Id"
                bg={"blackAlpha.100"}
                focusBorderColor={'transparent'}
                value={batchId}
                color={'white'}
                onChange={(e) => setBatchId(e.target.value)}
                required
              />
              <FormLabel htmlFor="batchId" >
                Batch Id
              </FormLabel>
            </FormControl>
            <FormControl variant='floating' id='productId' mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired>
              <Input id="productId" list="datalistOptions" id="exampleDataList"
              color={'white'}
                onChange={(e) => setProductId(e.target.value)}
                bg={"blackAlpha.100"}
                focusBorderColor={'transparent'}
                placeholder="Product Id"
                required  />
              <datalist id="datalistOptions">
                {product.map((prod, k) => (
                  <option key={k} value={prod.productId} />
                ))}
              </datalist>
              <FormLabel for="exampleDataList">Product Id</FormLabel>
            </FormControl>
          </Flex>
          <Flex  direction={{ base: 'column', md: 'row' }}
                          w="100%"
                          background="transparent"
                          justifyContent={{base: 'center', md:'space-evenly'}}
                          mt={10}
                        >
            <ReactToPrint
              onBeforeGetContent={()=>setStatus(false)}
              trigger={() => (
                <Button class='bn632-hover bn26'
                style={{height:'45px'}}
                  type="submit"
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          <Flex ref={componentRef}>
       {/* style={{ width:'21cm',
  minHeight: "29.7cm",
  padding: "1.25cm 0cm 1.35cm 0.9cm",
  margin: "1cm 0cm",
  border: "1px #d3d3d3 solid",
  borderRadius: "5px",
  background: "white"}} > */}
          {/* <Box height={'26.8cm'} direction={'row'}>
            {[...Array(70)].map((e, i) => (
              */}
              <QR
                bId={batchId}
                pdtId={productId}
                setDataUrl={setDataUrl}
                status={status}
              />
              {/* ))}
              </Box> */}
          </Flex>
          
          <Button
        class='bn632-hover bn26'
        type='submit'
        mt={{ base: '130px' }}
        onClick={downloadQRCode}> Download </Button>
          </Flex>
      </Card>
    </Flex>
  );
};

export default PrintQR;
