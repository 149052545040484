import axios from 'axios';
import { server } from '../../config/index';

function AddGeoLocation(productId,productName,location,city,countryCode,region,subRegion,cityCountry){
    
        const data = axios
            .post(`${server}/api/location/add`, {
                productId,
                productName,
                location,
                city,
                countryCode,
                region,
                subRegion,
                cityCountry
            })
            .then((res) => {
                console.log("Location Added Successfully")
                return res.data;
            })
            .catch((err) => console.error(err));
            addLocation(productId,productName,location,city,countryCode,region,subRegion) 
    return true;
}
function addLocation(productId,productName,location,city,countryCode,region,subRegion,cityCountry){
    const data = axios
            .post(`${server}/api/latestlocation/add`, {
                productId,
                productName,
                location,
                city,
                countryCode,
                region,
                subRegion,
                cityCountry
            })
            .then((res) => {
                console.log("Location Updated Successfully")
                return res.data;
            })
            .catch((err) => console.error(err));
}
export default AddGeoLocation;