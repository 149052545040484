import React, { useEffect, useState } from 'react';
import countryCodes from './countryCodes'; 
import Intro from '../../components/MainPage/Intro';
import MoreProducts from '../../components/MainPage/MoreProducts';
import Description from '../../components/MainPage/newPage/Description/Description';
import SocialLinks from '../../components/MainPage/SocialLinks';
import axios from "axios";
import ReadSingleProduct from 'components/CommonFunctions/DBCalls/ReadSingleProduct';
import { Flex, Text } from '@chakra-ui/react';
import { useLocation, Link } from "react-router-dom";
import Header from 'components/MainPage/NewHeader/Header';
import MainBG from '../../assets/images/NewBrew/MainBG.svg'
// import SnowAnime from 'components/SnowAnime/SnowAnime'
import AddGeoLocation from "components/CommonFunctions/AddGeoLocation";
import Process from 'components/MainPage/newPage/Process/Process';
import ProductBLockchain from 'components/MainPage/newPage/ProductIfo/ProductBLockchain';
import BreweryInfo from '../../components/MainPage/newPage/BreweryInfo/BreweryInfo';
import BlockchainInfo from '../../components/MainPage/newPage/BlockchainInfo/BlockchainInfo';
const MainPage = () => {
  const [product, setProduct] = useState("");
  const [isIntroLoaded, setIsIntroLoaded] = useState(false);
  let [locationStatus, setLocationStatus] = useState(false);
  let [status, setStatus] = useState(false);
  let [city, setCity] = useState("");
  let [location, setLocation] = useState({});
  let [countryCode, setCountryCode] = useState("");
  let [cityCountry,setCityCountry]=useState("");
  let [region, setRegion] = useState("");
  let [subregion, setSubregion] = useState("");
  let query = new URLSearchParams(useLocation().search);
  const bId = query.get("batchId");
  const id = query.get("productId");
  useEffect(async () => {
    let isMounted = true;
    let productId = id ? id : 1;
    let productFromServer = await ReadSingleProduct(productId);
    setProduct(productFromServer);

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, showError);
        if (locationStatus)
          AddGeoLocation(
            product.productId,
            product.productName,
            location,
            city,
            countryCode,
            region,
            subregion
          );
      } else {
        alert("Geolocation is not supported by this browser");
      }
    };
    // Find the latitude and longitude
    async function showPosition(position) {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;

      setLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      // location = {
      //   lat: position.coords.latitude,
      //   lng: position.coords.longitude
      // }
      let data = await axios
        .post(
          `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&featureTypes=&location=${lng}%2C${lat}`
        )
        .then((res) => {
          if (!res.data.error) {
            if (res.data.address) {
              const cntryName = countryCodes.filter((country) =>{
                if(country.alpha3 == res.data.address.CountryCode)
                  return country;
              })
              let ccName= res.data.address.City+","+cntryName[0].name;
              setCity(res.data.address.City);
              setCountryCode(res.data.address.CountryCode);
              setRegion(res.data.address.Region);
              setCityCountry(ccName);
              setSubregion(res.data.address.Subregion);
              setLocationStatus(true);
              // locationStatus=true;
            } else {
              console.log("Address is not available");
            }
          }
        });
    }
    !status && getLocation();


    const intro = () => {
      if (isMounted)
        setTimeout(() => {
          setIsIntroLoaded(true);
        }, 9000);
    };
    intro();
    return () => {
      isMounted = false;
    };
  }, [!status]);
  if (locationStatus && product) {
    setLocationStatus(false);
    AddGeoLocation(
      product.productId,
      product.productName,
      location,
      city,
      countryCode,
      region,
      subregion,
      cityCountry
    );
  }
  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
    }
  }
  //  console.log(product);


  const [windoWidth, setWindowWidth] = useState(window.innerWidth);
  let txt = "⚠️oh no ! Please switch to mobile screen mode";
  let txt2 = "";
  let switchTxt = windoWidth >= 850 ? txt : txt2;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className="position-relative min-vh-100 bg-white m-0 p-0">
      {!isIntroLoaded ? (
        <>

          <Intro />
        </>
      ) : (
        <>
        <div style={{overflow:'hidden'}}>
          <Text
            textAlign={"center"}
            color={"red"}
            fontSize={30}
            className="custom-font-subheading"
            fontWeight={"bold"}

          >
            {switchTxt}
          </Text>
          <div style={{ backgroundImage: `url(${MainBG})`, margin: 0, padding: 0 ,overflow:"hidden"}} >
            <Header product={product} />
            <div style={{overflow:"hidden"}}>
              <div style={{overflow:"hidden"}}>
              {/* <SnowAnime /> */}
              </div>
              <Description product={product} />
              <ProductBLockchain assetId={bId ? bId : 'test6'} />
              {product && product.processList ? (
                <Process product={product} />
              ) : (
                " "
              )}

              <BreweryInfo />
              <BlockchainInfo />
              {product && product.relatedProduct ? (
                <MoreProducts product={product} location={{
                  city,
                  countryCode,
                  region,
                  subregion,
                  cityCountry
                }} />
              ) : (
                " "
              )}
              <SocialLinks />
              {/* </SnowAnime> */}
            </div>
          </div>
          </div>
        </>

      )}
    </div>
  );
};

export default MainPage;
