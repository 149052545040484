import React, { useState } from "react";
import { useLocation} from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box, Flex, Text, Image
} from '@chakra-ui/react';
import logo from '../../assets/logo.png'
import {uri} from '../../config';
function AgeTracker() {
  let query = new URLSearchParams(useLocation().search);
  let hyperLink = query.get("hyperLink");
  const finalRef = React.useRef()
  const [status, setStatus] = useState(false)
  const [ModalStatus, setModalStatus] = useState(true);
 
  const handleClose=()=>{
    setModalStatus(false);
    window.open(`${hyperLink}`, '_blank');
    window.location.href=`${uri}/product`
  }

  return (
    <>
      <Modal closeOnOverlayClick={false} size={'sm'} 
        isOpen={ModalStatus}
        onClose={handleClose} >
        <ModalOverlay bgColor={'blackAlpha.800'} />
        <ModalContent alignSelf={'center'} mx={5}>
          {!status ? <ModalBody>
            <Flex Width='100' height='200' direction={'column'} bgColor='white' justifyContent={'center'}>
             <Flex  width='100%' direction={'row'} justifyContent={'center'}>
              <Flex width='50%' height='90%'  justifyContent={'left'}  >
                <Image src={logo} alt='Dan Abramov' bgColor={'black'} borderRadius={100} />
                </Flex>
                <Flex width='100%' height='100%' alignItems={'center'} justifyContent={'center'} >
                <Text  textAlign={'center'}   fontWeight={'bold'} fontSize={18} fontFamily={'charcuterie-block'}>AGE VERIFICATION</Text>
              </Flex>
            </Flex>
              <Flex width='100%'
                height='0px'
                mt={5}
                border='1px solid rgba(0, 0, 0, 0.3)'
                boxShadow='0px 1px 1px rgba(0, 0, 0, 0.5)'
              > </Flex>
            </Flex>
            <Flex direction={'row'} width={'100%'} justifyContent={'center'}>
              <Text color={'blackAlpha.600'}>You must be <span style={{ color: 'red' }} >18</span> years old to Enter</Text>
            </Flex>
            <Flex justifyContent={'center'} m={6} direction={{base: 'column', md: 'row'}} >
              <Button  bgColor={'#32cd32'} mb={{base: 5, md:0}} mr={{base:0, md:5}}  onClick={handleClose} w={{ base: '100%', md: '25%' }}>Yes</Button>
              <Button bgColor={'#FBE5E5'} onClick={() => { setStatus(true) }} w={{ base: '100%', md: '25%' }}>No</Button>
            </Flex>
          </ModalBody> : <ModalBody>
            <Flex width='100' height='150' direction={'column'} bgColor='white' justifyContent={'center'}>
              <Flex direction={'row'} width={'100%'} justifyContent={'center'}>
                <Text color={'black'} fontSize={40} >Sorry...</Text>
              </Flex>
              <Flex width='225'
                height='0px'
                mt={1}
                border='1px solid rgba(0, 0, 0, 0.3)'
                boxShadow='0px 1px 1px rgba(0, 0, 0, 0.5)'
              > </Flex>
              <Flex direction={'row'} m={2} width={'100%'} justifyContent={'center'}>
                <Text color={'blackAlpha.700'} fontSize={14} >You are not old enough to view the site ...</Text>
              </Flex>
            </Flex>
          </ModalBody>}
        </ModalContent>
      </Modal>
    </>
  )
}
export default AgeTracker