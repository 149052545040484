import axios from 'axios';
import { bcuri ,api_key} from '../../../config';
import Swal from 'sweetalert2'
async function UpdateBlockchainData(batchId,inputList){
    let data = {
      inputList
    }
    const header = {
      "content-type":
        "application/json, text/plain, application/x-www-form-urlencoded",
      headers: { Authorization: `Bearer ${api_key}` },
    };
    //server call to add new stage
    const serverdata = await axios
      .post(`${bcuri}/add`, {batchId, data}, header)
      .then((res) => {
        //return true if data added
        return true;
      })
      .catch((err) => {
        return err;
        // console.log(err);
      });
    let product = serverdata ;
    return product;
}
export default UpdateBlockchainData;