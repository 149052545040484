import React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";
import backbg from '../../assets/images/BrewToonLogin.svg';
import bottle7 from '../../assets/images/bottles/bottle7.png';
import bottle4 from '../../assets/images/bottles/bottle4.png';
import bottle6 from '../../assets/images/bottles/bottle6.png';
import bottle3 from '../../assets/images/bottles/bottle3.png';
import bottle5 from '../../assets/images/bottles/bottle5.png';
import {

    Text, Box, Flex, Img, Image, Heading, Center, Input, Stack, InputRightElement,
    InputGroup,
    Button, FormControl, FormLabel, FormHelperText
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "./signinValid";
import { server, uri } from "../../config/index";
import Swal from 'sweetalert2';
import './Login.css';
const SignIn = () => {
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    // functionality
    // validation starts
    // form Validation
    const initValues = {
        email: "",
        password: "",
    };

    const [initialValue, setinitialValue] = useState(initValues);

    const schema = yup
        .object()
        .shape({
            email: yup.string().email().required(REQUIRED_VALIDATION("⚠️ Email")),
            password: yup
                .string()
                .required(REQUIRED_VALIDATION("⚠️ Password"))
                .min(4, "⚠️ Password must be min 4 char long!"),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        resolver: yupResolver(schema),
        defaultValues: initialValue,
    });

    const onSubmit = (values) => {
        login(values);
    };
    // User login
    async function login(values) {

        let data = { email: values.email, password: values.password };
        const serverdata = await axios
            .post(`${server}/api/users/login`, data, {
                headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
                if (res.status === 200) {
                    Swal.fire(
                        'Successfully LoggedIn!',
                        'success'
                    )

                    return res.data;
                } else {
                    Swal.fire(
                        'Invalid Credentials!',
                        'error'
                    )
                    window.location.href = `${uri}/`;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        const AdminData = await serverdata;

        localStorage.setItem(
            "tg-token",
            JSON.stringify({
                token: AdminData.token,
                userName: AdminData.userName,
            })
        );
        window.location.href = ` ${uri}/admin/dashboard`;
    }
    // validation ends
    return (
        <Flex w={'100%'} height={'100vh'} backgroundImage={`url(${backbg})`}
            backgroundSize={'cover'}
            backgroundRepeat="no-repeat">
            {/* <Flex  justifyContent={'center'} > */}
            {/* <Img src={logo} alt="logo" w={'30%'} h={'50%'} ml={'160'} mt={50}/> */}
            {/* </Flex> */}
            <Flex zIndex={1} w={'100%'}>
                <Flex w={'60%'} >
                    <Image src={bottle3}  h={250} alignSelf={'center'} position={'absolute'} ml={"8%"} mt={'6%'} boxShadow={"0 3px 10px rgb(0 0 0 / 0.2)"} className='kuppy' />
                    <Image src={bottle4}  h={330} alignSelf={'center'} position={'absolute'} ml={"15%"} mt={'6%'} className='kuppy' />
                    <Image src={bottle7}  h={388} alignSelf={'center'} position={'absolute'} ml={"25%"} mt={'6%'} zIndex={4} className='kuppy' />
                    <Image src={bottle6}  h={330} alignSelf={'center'} position={'absolute'} ml={"35%"} mt={'6%'} className='kuppy' />
                    <Image src={bottle5}  h={250} alignSelf={'center'} position={'absolute'} ml={"43%"} mt={'6%'} className='kuppy' />
                </Flex>
                <Flex w={'80%'} justifyContent={'center'}>
                    <Flex direction={'column'} w={'100%'} h={'60%'} mt={'23%'} alignItems={'center'}>
                        <Text textAlign={'center'} fontWeight={'400'} fontSize={'45px'} mb={35} color={'white'} fontFamily='Oldenburg' textShadow=" 0px 4px 4px rgba(0, 0, 0, 0.25)">LOGIN</Text>
                        <Flex w={'50%'} h={'60%'} justifyContent={'center'}>
                            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', justifyContent: 'center' }} >
                                <FormControl mb={10} w={'100%'} >
                                    <Input id="email"
                                        type="email"
                                        name="email"
                                        height={45}
                                        border={'none'}
                                        fontSize={16}
                                        style={{ textIndent: 15 }}
                                        bgColor="#edeadf"
                                        shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                                        w={'100%'}
                                        {...register("email")} placeholder='   Enter Email' fontFamily='Oldenburg' borderRadius='8px' />
                                    {errors && errors.email && (
                                        <FormHelperText color="red" className="error2" alignSelf={"self-start"} mt={3} >
                                            {errors.email.message && errors.email.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl mb={50} w={'100%'} >
                                    <InputGroup>
                                        <Input id="password"
                                            type={show ? 'text' : 'password'}
                                            name="password"
                                            height={45}
                                            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                                            border={'none'}
                                            fontSize={16}
                                            bgColor="#edeadf"
                                            p={2}
                                            w={'100%'}
                                            style={{ textIndent: 15 }}
                                            {...register("password")} placeholder='   Enter Password' fontFamily='Oldenburg' borderRadius='8px' />
                                        <InputRightElement position={'absolute'} left={"85%"} fontSize={25}>
                                            <Button onClick={handleClick} fontSize={30} background={'none'} border={'none'} >
                                                {show ? "🙊" : "🙈"}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    {errors && errors.password && (
                                        <FormHelperText color="red" className="error2" alignSelf={"self-start"}>
                                            {errors.password.message && errors.password.message}
                                        </FormHelperText>)}
                                </FormControl>
                                <Flex mt={50} justifyContent={'center'}>
                                    <Button type="submit" shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"} border={'none'} height={10} p={4} width={"35%"} color={'white'} fontFamily='Oldenburg' borderRadius='8px' bg={'#402E29'} fontSize={18} letterSpacing={1}>LOGIN</Button>
                                </Flex>
                            </form>
                        </Flex>
                    </Flex>
                </Flex>

            </Flex>
        </Flex>


    )

}
export default SignIn;