const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-32px) translateX(-10px)',
}

export const formLabelStyles ={
    components: {
      Form: {
        variants: {
          floating: {
            container: {
              _focusWithin: {
                label: {
                  ...activeLabelStyles,
                },
              },
              'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label':
                {
                  ...activeLabelStyles,
                },
              label: {
                top: 0,
                left: 0,
                zIndex: 2,
                position: 'absolute',
                backgroundColor: 'transparent',
                pointerEvents: 'none',
                color:'whiteAlpha.500',
                mx: 3,
                px: 1,
                my: 2,
              },
            },
          },
        },
      },
    },
  }