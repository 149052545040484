// Chakra imports
import { Flex, StatDownArrow, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { server } from "../../config/index";
import { Table, Thead, Tbody, Tr, Th, Td, chakra } from "@chakra-ui/react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import Card from "../../components/Card/Card";
import { Line } from "react-chartjs-2";
import PieCharts from "./PieChart";
// import MoreProducts from "components/MainPage/MoreProducts";
export default function Dashboard() {
  const [serverData, setServerData] = useState([]);
  const [moreProduct, setMoreProduct] = useState([]);
  useEffect(async () => {
    let moreproduct = getMoreProductAnalysis();
    let data = getLocation();
  }, []);
  async function getLocation() {
    let data = await axios
      .get(`${server}/api/location/sort`, {
        timeout: 6000,
      })
      .then((res) => {
        setServerData(res.data);
        return res.data;
      })
      .catch((err) => console.error(err));
    return data;
  }
  async function getMoreProductAnalysis() {
    let data = await axios
      .get(`${server}/api/analysis`, {
        timeout: 6000,
      })
      .then((res) => {
        setMoreProduct(res.data);
        return res.data;
      })
      .catch((err) => console.error(err));
    return data;
  }

  window.google.charts.load("current", {
    packages: ["geochart", "corechart", "controls"],
    // Note: you will need to get a mapsApiKey for your project.
    // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings (trackgenesis@gmail.com)

    mapsApiKey: "AIzaSyCjjg3Bo0gdOEidwnlyaENT_4vpSkNX1IE",
  });

  window.google.charts.setOnLoadCallback(drawGeoMap);
  function drawGeoMap() {
    var data = new window.google.visualization.DataTable();
    data.addColumn("string", "City");
    data.addColumn("number", "User");

    serverData.map((sdata, i) =>
      //  countryNames = countryCodes.filter(country =>{
      //   if(country.alpha3 == sdata.CountryCode){
      //     return country;
      //   }
      // })

      data.addRow([sdata._id, sdata.count])
    );
    console.log(serverData);
    var options = {
      // region: "142",
      displayMode: "markers",
      colorAxis: { colors: ["#FF5600", "#141E61"] },
      backgroundColor: "#42b5eb",
      datalessRegionColor: "#BEDCFA",
      defaultColor: "#F5F5F5",
      defaultBorderRadius: "100px",
    };
    // #D0E8F2
    var chart = new window.google.visualization.GeoChart(
      document.getElementById("geo_chart")
    );
    chart.draw(data, options);
  }

  const columns = [
    {
      dataField: "productName",
      text: "Product Name",
      sort: true,
      headerAlign: "center",
      attrs: { title: `productName` },
      style: {
        width: "350px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "rgb(68, 68, 68)",
      },
      headerStyle: {
        color: "black",
      },
      // filter: textFilter()
    },
    {
      dataField: "productCount",
      text: "Count",
      sort: true,
      headerAlign: "center",
      style: {
        textAlign: "center",
        color: "rgb(68, 68, 68)",
      },
      headerStyle: {
        color: "black",
      },
    },
  ];
  const defaultSorted = [
    {
      dataField: "productCount",
      order: "desc",
    },
  ];

  return (
    <Flex direction={"row"}>
      <Flex flexDirection="column" p={0} m={0}>
        <Card bg="rgba(255, 255, 255, 0.25)">
          <Text
            color={"whiteAlpha.900"}
            textShadow={"0px 4px 8px rgba(0, 0, 0, 0.5)"}
            letterSpacing={1}
            // bgGradient={'linear-gradient(180deg, #0075AD 3%, rgb(70, 170, 88) 95%)'} bgClip='text'
            fontSize="xl"
            fontWeight="bold"
            mb={6}
          >
            {" "}
            CUSTOMER LOCATION
          </Text>
          <Flex
            id="geo_chart"
            style={{
              position: "relative",
              height: "50vh",
              width: "38vw",
              padding: 0,
              margin: 0,
              // border: "3px #000 solid",
              borderRadius: "50px",
            }}
          ></Flex>
          {/* </div> */}
        </Card>
      </Flex>

      <Flex direction={"column"}>
        <Card
          ml={2}
          bg="rgba(255, 255, 255, 0.25)"
          width={"100%"}
          height="100%"
        >
          <Text
            color={"white"}
            textShadow={"0px 4px 8px rgba(0, 0, 0, 0.5)"}
            letterSpacing={1}
            // bgGradient={'linear-gradient(180deg, #0075AD 3%, rgb(70, 170, 88) 95%)'} bgClip='text'
            fontSize="xl"
            fontWeight="extrabold"
            mb={6}
          >
            {" "}
            Most Clicked Products{" "}
          </Text>
          <Flex>
            {moreProduct.length > 0 ? (
              <PieCharts moreProducts={moreProduct} />
            ) : (
              "No Data Available"
            )}
            {/* {moreProduct ?  <BootstrapTable bootstrap4 keyField='id' data={moreProduct} columns={columns}  defaultSorted={ defaultSorted } /> :"Product List is Empty"}  */}
          </Flex>
        </Card>
        {/* {moreProduct ?  <BootstrapTable bootstrap4 keyField='id' data={moreProduct} columns={columns}  defaultSorted={ defaultSorted } /> :"Product List is Empty"}  */}
      </Flex>
    </Flex>
  );
}
