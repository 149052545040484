import { Alert } from '@chakra-ui/react';
import axios from 'axios';
import { server,tag_name } from '../../../config';
import Swal from 'sweetalert2'
async function ReadProductInfo(){

    const rdata = await axios
        .get(`${server}/api/blockchaindata`)
        .then((res) => {
          if(res.status==200){
          return res;
          }
          else  if(res.status==203){
            return false;
          }
        })
        .catch((err) =>
        alert("Error updating product")
        // Swal.fire("Error Adding This Id/Already Exists",'error')    
        );
      
      console.log(rdata);
     return rdata;
    }


export default ReadProductInfo;