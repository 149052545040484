
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import 'animate.css';
import { ChakraProvider } from '@chakra-ui/react';

AOS.init();

ReactDOM.render(
  <ChakraProvider>
    <App />
    </ChakraProvider>,
  document.getElementById('root')
);
