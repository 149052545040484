import React, { Fragment } from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Container,
  Link,
  Button,
} from "@chakra-ui/react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import logo from "../../assets/logo.png";
import "./Sociallinks.css";
import { uri } from "config";
import LogoSvg from "./LogoSvg";
import "../../App.scss";
import handleMoreProduct from "components/CommonFunctions/DBCalls/handleMoreProduct";
import logos from "../../assets/new/1.png";
const MoreProducts = (props) => {
  let product = props.product;
  let location = props.location;
  let relatedProduct = product.relatedProduct;
  let viewBox = "-56 -76 357.94 607.46";
  const handleRedirect = (data) => {
    handleMoreProduct(data.relatedproductName, location);
    window.location.href = `${uri}/age?hyperLink=${data.hyperLink}`;
  };
  return (
    <Flex flexDirection={"column"} mt={8} display={{ lg: "none", xl: "none" }}>
      <Flex pl={5}>
        <Image src={logos} alt="Process" />
        <Text
          fontWeight="700"
          fontSize="30px"
          lineHeight="133.69%"
          textTransform="uppercase"
          className="custom-font-heading"
          data-aos="fade-down"
          data-aos-delay="300"
          data-aos-duration="300"
          data-aos-easing="linear"
        >
          MORE PRODUCTS
        </Text>
      </Flex>
      <Flex p={4} justifyContent={"center"} overflow={"hidden"}>
        <Carousel
          swipeable={true}
          showIndicators={false}
          showThumbs={true}
          autoPlay={true}
          infiniteLoop={true}
          showStatus={false}
          showArrows={false}
          className="css-mtqz4b"
        >
          {relatedProduct.map((data, i) => (
            <Button
              bg={"transparent"}
              border={"none"}
              borderRadius="27px"
              width={"50%"}
              height={"360px"}
              onClick={() => handleRedirect(data)}
            >
              <Container
                width={"50%"}
                justifyContent={"center"}
                justifyItems={"center"}
                height={"415px"}
                key={i}
                background="linear-gradient(180deg, rgba(80, 176, 203, 0.41) 0%, rgba(202, 193, 110, 0.38) 100%)"
                borderRadius="27px"
              >
                <Flex
                  position={"absolute"}
                  top={5}
                  width={"18%"}
                  justifyContent={"center"}
                  height={"72%"}
                  left={"298px"}
                >
                  <Image
                    zIndex={1}
                    width={"100%"}
                    height={"100%"}
                    src={data.imageLink}
                    alignSelf="center"
                  />
                </Flex>
                <LogoSvg fill={data.color} viewBox={viewBox} />
                <Flex
                  borderRadius="27px 27px 0px 0px"
                  justifyContent={"center"}
                  width="100%"
                  height={"15%"}
                  mt={-216}
                  fontSize={"xl"}
                  zIndex={2}
                >
                  <Text
                    color={"black"}
                    p={2}
                    textAlign="center"
                    className="custom-font-heading"
                  >
                    {" "}
                    {data.relatedproductName}
                  </Text>
                </Flex>
              </Container>
            </Button>
          ))}
        </Carousel>
      </Flex>
    </Flex>

    // -------------------------------------------------------old code-------------------------------
    // <section className="position-relative px-3">
    //   <h3 className="border-bottom custom-font-heading mb-3">More Products</h3>
    //   <div className="container">
    //     <div className="row row-cols-2 row-cols-md-4">
    //       {products.map((data, i) => (
    //         <Fragment key={i}>
    //           <div
    //             className="col p-3"
    //             style={{ '--accent-clr': `${data.color}` }}
    //           >
    //             <a
    //               href={data.link}
    //               className="text-decoration-none text-center mx-auto d-block py-2 shadow-lg rounded-3 bg-white bg-opacity-75 more-product-card"
    //               target="_blank"
    //               rel="noreferrer"
    //               title={data.productName}
    //             >
    //               <div className="more-product-card-logo">
    //                 <LogoSvg />
    //               </div>
    //               <img
    //                 src={data.imagePath}
    //                 alt={data.productName}
    //                 className="img-fluid product-image"
    //               />
    //               <div className="product-name">
    //                 <h4 className="text-decoration-none custom-font-heading link-dark fs-5 px-1 text-center">
    //                   {data.productName}
    //                 </h4>
    //               </div>
    //             </a>
    //           </div>
    //         </Fragment>
    //       ))}
    //     </div>
    //   </div>
    // </section>
    // -------------------------------------------old code------------------------------------
  );
};

export default MoreProducts;
