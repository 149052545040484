/** @format */


function ParticleAnime() {

  let particleWindowHeight = 700,
  speed = 1,
  speedcam = 2,
  noofparticles = 5e3,
  sizeofparticles = 99,
  lastz = 2e3;
lastz -= lastz % speedcam;
let camera,
  scene,
  renderer,
  particles,
  i,
  j,
  h,
  color,
  size,
  texture = [
    "https://res.cloudinary.com/dj6eghdzu/image/upload/v1652962257/Brewtoon/cryopop%20Pellets/pellets1-1_xxalhr.png",
    "https://res.cloudinary.com/dj6eghdzu/image/upload/v1652962276/Brewtoon/cryopop%20Pellets/Pellets2-1_uy5zdc.png",
    "https://res.cloudinary.com/dj6eghdzu/image/upload/v1652962295/Brewtoon/cryopop%20Pellets/pellets3-1_szu9iq.png",
    "https://res.cloudinary.com/dj6eghdzu/image/upload/v1652962308/Brewtoon/cryopop%20Pellets/pellets4-1_lfajik.png",
    "https://res.cloudinary.com/dj6eghdzu/image/upload/v1652962322/Brewtoon/cryopop%20Pellets/pellets5-1_wehqao.png",
    "https://res.cloudinary.com/dj6eghdzu/image/upload/v1652962330/Brewtoon/cryopop%20Pellets/pellets6-1_wwy5rn.png",
    "https://res.cloudinary.com/dj6eghdzu/image/upload/v1652962340/Brewtoon/cryopop%20Pellets/pellets7-1_bn1vll.png",
  ],
  noofsprites = texture.length,
  particlespersprite = noofparticles / noofsprites,
  mouseX = 0,
  mouseY = 0,
  exx = 0,
  eyy = 0,
  flagcam = 0,
  windowHalfX = window.innerWidth / 2,
  windowHalfY = window.innerHeight / 2,
  flagx = new Array(),
  flagy = new Array(),
  flagz = new Array(),
  ox1 = new Array(),
  oy1 = new Array(),
  oz1 = new Array(),
  sprite = new Array(),
  geometry = new Array(),
  material = new Array(),
  particle = new Array();
function init() {
  for (
    (camera = new THREE.PerspectiveCamera(
      55,
      window.innerWidth / window.innerHeight,
      2,
      noofparticles * noofsprites
    )).position.z = 0,
      $("*").mousemove(function (e) {
        (exx = e.pageX - windowHalfX),
          (exx -= exx % speed),
          (eyy = windowHalfY - e.pageY),
          (eyy -= eyy % speed);
      }),
      (scene = new THREE.Scene()).fog = new THREE.FogExp2(2331, 5e-4),
      i = 0;
    i < noofsprites;
    i++
  )
    geometry.push(new THREE.Geometry());
  for (i = 0; i < noofsprites; i++)
    sprite.push(THREE.ImageUtils.loadTexture(texture[i]));
  for (i = 0; i < particlespersprite; i++)
    for (j = 0; j < noofsprites; j++)
      geometry[j].vertices.push(generateVertex());
  for (i = 0; i < noofsprites; i++)
    (material[i] = new THREE.ParticleBasicMaterial({
      size: sizeofparticles,
      sizeAttenuation: !0,
      map: sprite[i],
      transparent: !0,
    })),
      (material[i].alphaTest = 0.5),
      (material[i].transparent = !1);
  for (i = 0; i < noofsprites; i++)
    (particle[i] = new THREE.ParticleSystem(geometry[i], material[i])),
      (particle[i].sortParticles = !0),
      scene.add(particle[i]);
  (renderer = new THREE.WebGLRenderer({ clearAlpha: 1 })).setSize(
    $(".et_pb_section_0").first().innerWidth(),
    particleWindowHeight
  ),
    $(".et_pb_section_0").first().append(renderer.domElement),
    document.addEventListener("mousemove", onDocumentMouseMove, !1),
    document.addEventListener("touchstart", onDocumentTouchStart, !1),
    document.addEventListener("touchmove", onDocumentTouchMove, !1),
    window.addEventListener("resize", onWindowResize, !1);
}
function onWindowResize() {
  (windowHalfX = $(".et_pb_section_0").first().innerWidth() / 2),
    (windowHalfY = particleWindowHeight / 2),
    (camera.aspect =
      $(".et_pb_section_0").first().innerWidth() / particleWindowHeight),
    camera.updateProjectionMatrix(),
    renderer.setSize(
      $(".et_pb_section_0").first().innerWidth(),
      particleWindowHeight
    );
}
function onDocumentMouseMove(e) {
  (mouseX = e.clientX - windowHalfX), (mouseY = e.clientY - windowHalfY);
}
function onDocumentTouchStart(e) {
  1 == e.touches.length &&
    (e.preventDefault(),
    (mouseX = e.touches[0].pageX - windowHalfX),
    (mouseY = e.touches[0].pageY - windowHalfY));
}
function onDocumentTouchMove(e) {
  1 == e.touches.length &&
    (e.preventDefault(),
    (mouseX = e.touches[0].pageX - windowHalfX),
    (mouseY = e.touches[0].pageY - windowHalfY));
}
function animate() {
  requestAnimationFrame(animate), render();
}
function generateVertex() {
  var e = new THREE.Vector3();
  return (
    (ox1[i] = parseInt(4e3 * Math.random()) - 2e3),
    (ox1[i] = ox1[i] - (ox1[i] % speed)),
    (e.x = ox1[i]),
    (flagx[i] = 0),
    (oy1[i] = parseInt(2e3 * Math.random()) - 1e3),
    (oy1[i] = oy1[i] - (oy1[i] % speed)),
    (e.y = oy1[i]),
    (flagy[i] = 0),
    (oz1[i] =
      parseInt((-noofparticles / 2) * Math.random()) + noofparticles / 4),
    (oz1[i] = oz1[i] - (oz1[i] % speed)),
    (e.z = oz1[i]),
    (flagz[i] = 0),
    e
  );
}
function render() {
  Date.now();
  if (0 != exx && 0 != eyy)
    for (var e = 0; e < geometry[0].vertices.length; e++)
      1 == flagy[e] &&
        (geometry[0].vertices[e].y > oy1[e]
          ? (geometry[0].vertices[e].y -= speed)
          : geometry[0].vertices[e].y < oy1[e]
          ? (geometry[0].vertices[e].y += speed)
          : geometry[0].vertices[e].y == oy1[e] && (flagy[e] = 0));
  0 != speedcam &&
    (0 == flagcam
      ? (camera.position.z += speedcam)
      : 1 == flagcam && (camera.position.z -= speedcam)),
    (camera.position.z != lastz && camera.position.z != -lastz) ||
      (flagcam = 0 == flagcam ? 1 : 0),
    (camera.position.x += 0.05 * (mouseX - camera.position.x)),
    (camera.position.y += 0.05 * (-mouseY - camera.position.y)),
    camera.lookAt(scene.position),
    renderer.render(scene, camera);
}
init(), animate();

}

export default ParticleAnime;
