import axios from 'axios';
import { server,uri } from '../../../config';
async function ReadProductData() {

    let data = await axios
    .get(`${server}/api/products/`)
    .then((res) => {
     
      return res.data;
    })
    .catch((err) => console.error(err));
  return data;
}
export default ReadProductData;