import axios from 'axios';
import React, { useState } from 'react';
import { FormLabel, Input, Td, Flex, Th, Tr, Table, TableCaption, Button, FormControl, Tbody } from '@chakra-ui/react';
import ReadProductInfo from 'components/CommonFunctions/DBCalls/ReadBlockchainData';
const TrackList = (params) => {
  let [blockchainData, setBlockchainData] = useState([]);

  const [batchId, setBatchId] = useState('');
  const [updateStatus, setUpdateStatus] = useState(false);
  const [Status, setStatus] = useState(false);
  var prod;
  const logData = localStorage.getItem('tg-token');
  let logDetails = JSON.parse(logData);

  async function viewProduct(e) {
    setStatus(true)
    e.preventDefault();
    if (!batchId) {
      alert("Please Enter Batch Id")
      return;
    }
    let prodata = await ReadProductInfo(batchId);
    if (prodata.data && (batchId==='4' || batchId==='1' || batchId==='test4')) {
      (prodata.data).forEach(element => {
        if(element.assetId === 'BrewToontest4')
        {
          setStatus(false)
          setUpdateStatus(true);
          setBlockchainData(element.data);
        }
      });
    } else {
      setStatus(false)
      alert("No Data Avilable")
      console.log("No Data Avilable");
    }

  }
  params.setBatchId(batchId);
  params.setUpdateStatus(updateStatus);
  return (
    <Flex direction={{ base: 'column', md: 'column' }}
      w="100%"
      justifyContent={"space-between"}
      background="transparent"
      marginBottom={"4"}>
      <form onSubmit={viewProduct}>
        <Flex direction={{ base: 'column', md: 'column' }}
          w="100%"
          justifyContent={"space-between"}
          background="transparent"
          marginBottom={"4"}>
          <FormControl variant='floating' id="parameterName" mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired>
            <Input
              type="text"
              focusBorderColor={'transparent'}
              bg={"blackAlpha.100"}
              color={'white'}
              id="batchId"
              placeholder="Batch Id"
              value={batchId}
              onChange={(e) => setBatchId(e.target.value)}
              required
            />
            <FormLabel htmlFor="username" >
              Batch Id
            </FormLabel>
          </FormControl>
          {!Status && <Button type="submit" class='bn632-hover bn26' style={{ marginTop: '25px' }} w={{ base: '100%', md: '25%' }}> View </Button>}
          {Status && <Button
            isLoading
            loadingText='Loading'
            // variant='outline'
            spinnerPlacement='start'
            style={{ marginTop: '25px' }} w={{ base: '100%', md: '35%' }}
          >
            Submit
          </Button>}
        </Flex>
      </form>
      {blockchainData && <Flex direction={{ base: 'column', md: 'row' }}
        w="100%"
        justifyContent={"space-between"}
        background="transparent"
        marginBottom={"4"}>
        <Table >
          <Tbody justifyItems={'center'} p={'5px'}>
            {blockchainData && blockchainData.map((data, i) => (
              <>
                {/* <TableCaption color="white" w={'100%'}  fontSize={'md'}  fontWeight={'bold'}>STAGE {++i}</TableCaption> */}
                {(data.inputList).map((produc, i) => (
                  // console.log(produc.parameterName)
                  <>
                    <Tr key={i}>
                      <Th color='white'>{produc.parameterName}</Th>
                      <Td color='white'> {produc.value}</Td>
                    </Tr>
                  </>
                ))}
              </>
            ))}
          </Tbody>
        </Table>

      </Flex>}

    </Flex>

  );
};

export default TrackList;
