import { Button, Flex, useDisclosure } from "@chakra-ui/react";
// import BootstrapTable from 'react-bootstrap-table-next';
import Card from 'components/Card/Card';
import React, { useEffect, useState } from 'react';
import { Link, Navigate } from "react-router-dom";
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import { server } from "config";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import pagenationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import MasterFile from "components/ProductDetails/MasterFile";
import ReadProductData from "components/CommonFunctions/DBCalls/ReadProductData";
import Swal from 'sweetalert2'
const ProductList = () => {
  const [products, setProducts] = useState([]);
  const logData = localStorage.getItem('tg-token');
  const { isOpen, onOpen, onClose } = useDisclosure()
  let id;
  let logDetails= JSON.parse(logData);
   const pagination = pagenationFactory({
    page :1,
    sizePerPage :10,
    firstPageText:'<<',
    prePageText:'<',
    nextPageText:'>',
    lastPageText :'>>',
    showTotal:true,  
   });
  useEffect(()=>{
    const getProducts = async () => {
      const productsFromServer = await ReadProductData();
      setProducts(productsFromServer);
    };     
 getProducts();
  },[])
  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <i className={ formatExtraData[cell] } onClick={onDelete} />
    );
  }
  const columns =[
    {
      dataField :'productId',
      text: 'Id',
      sort : true,
      headerAlign: 'center',
      style:{
        textAlign:'center',
        color:"rgb(68, 68, 68)"
      } ,
      headerStyle: {
        color: 'black'
      }
    },
    {
      dataField :'productName',
      text: 'Name',
      sort : true,
      headerAlign: 'center',
      attrs: { title: `productName` },
      style :{
        width: '350px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color:"rgb(68, 68, 68)"
      }
      ,
      headerStyle: {
        color: 'black'
      }
      // filter: textFilter()
    },
    {
      dataField :'productDesc',
      text: 'Description',
      headerAlign: 'center',
      style :{
        width: '250px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color:"rgb(68, 68, 68)"
      }
      ,
      headerStyle: {
        color: 'black'
      }
    },
    {
      dataField :'productBrand',
      text: 'Brand',
      headerAlign: 'center',
      style :{
        width: '250px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color:"rgb(68, 68, 68)"
      }
      ,
      headerStyle: {
        color: 'black'
      }
    },
    {
      dataField :'imageLink',
      text: 'Image',
      sort : true,
      headerAlign: 'center',
      style :{
        width: '250px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color:"rgb(68, 68, 68)"
      },
      headerStyle: {
        color: 'black'
      }
    },
    {
      dataField :'productId',
      text: 'Edit',
      headerAlign: 'center',
      headerStyle: {
        color: 'black'
      },
      formatter: (cellContent, row) => {
        return (
           <Link
              id="edit"
              className="btn btn-lg"
              style={{color:"rgb(68, 68, 68)"}}
              to={`/admin/addProduct/${row.productId}`}
              onLoad={onOpen}
              >
          <FaEdit /></Link>
         
          // <MasterFile id={row.productId} />
        );
      }  
    },
    {
      dataField :'productId',
      text: 'Delete',
      headerAlign: 'center',
      headerStyle: {
        color: 'black'
      },
      formatter: (cellContent, row) => {
        return (
          <button className="btn btn-lg text-danger"
            onClick={() => onDelete(row.productId)}
          >
           <FaTrashAlt/>
          </button>
        );
      }
    },
]
var swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success m-2',
    cancelButton: 'btn btn-danger'
  },
  buttonStyling:false
 
})

  const onDelete = async (id) => {
  
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      confirmButtonMargin:'3px',
      confirmButtonColor:'rgb(146, 9, 9)',
      cancelButtonColor:'rgb(3, 70, 3)'
    }).then((result) => {
      if (result.isConfirmed) {
          handleDelete(id);
       
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          'Your file is safe :)',
          'error'
        )
      }
    }) 
  };

  const handleDelete = async (id) => {
    const res = await fetch(`${server}/api/products/delete/${id}`, {
      // headers: { 'x-access-token': logDetails.token},
      method: 'DELETE',
    });
    const deleted = () => {
      swalWithBootstrapButtons.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
      window.location.pathname='/admin/productList';
      
    };
    res.status === 200 ? deleted() : alert('Error Deleting This Product');
  };

    return (
        <Flex>
            <Card>
                <MasterFile/>
                <Flex>
              {products ?  <BootstrapTable bootstrap4 keyField='id' data={products} columns={columns}  /> :"Product List is Empty"} 
                </Flex>
            </Card>
        </Flex>
    );
}

export default ProductList;