import React, { useState, useRef } from "react";
import ReactPlayer from 'react-player/youtube'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  Text
} from '@chakra-ui/react';

import { AiTwotoneFire, AiOutlineCloseCircle } from "react-icons/ai";
import { FaBookReader } from "react-icons/fa";
import './video.css'
const Video = (prop) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  // console.log(data);
  const btnRef = useRef()
  let link = prop.link;
  let description = prop.description;
  let heading = prop.heading

  return (
    <>
      <Flex data-aos="fade-right"
        data-aos-delay="300"
        data-aos-duration="600"
        data-aos-easing="ease-in-sine">
        <Button
          px={6}
          justifyContent={"center"}
          alignSelf={"center"}
          cursor="pointer"
          background="linear-gradient(90.12deg, rgba(2, 148, 187, 0.748841) 8.92%, rgba(0, 146, 185, 0.78) 15.63%, rgba(34, 171, 208, 0.3198) 105.45%);"
          box-shadow="inset 0px 4px 4px rgba(0, 0, 0, 0.29)"
          borderRadius="15px"
          className="custom-font-product-description"
          ref={btnRef}
          onClick={() => {
            onOpen();
          }}
        >
          <Text fontWeight="bold"
            fontSize="16px"
            textAlign={'center'}
            lineHeight="50%"
            fontFamily={"custom-font-subheadin"}
            textTransform=" uppercase">Read more</Text>
        </Button>
      </Flex>
      <Modal
        onClose={onClose}
        finalFocusRef={btnRef}
        isOpen={isOpen}
        scrollBehavior='inside'
        isCentered
        allowPinchZoom={true}
        // size={'lg'}
        overflow={'auto'}
      >
        <ModalOverlay bg="blackAlpha.300"
          backdropFilter="auto"
          backdropInvert="25%"
          backdropBlur="4px" />
        <ModalContent mx={10} justifyContent={'center'} bg={"transparent"} >
          <Button
            backgroundColor={"black"}
            boxShadow="0px 4px 12px 2px rgba(0, 0, 0, 0.5)"
            p={0}
            mb={3}
            color={"white"}
            borderRadius={100}
            alignSelf={"end"}
            size={"md"}
            // id="closeBut"
            // position={'absolute'}
            onClick={onClose}
          >
            <AiOutlineCloseCircle size={25} />
          </Button>
          {/* <ModalHeader> */}

          <Flex className="video" borderBottomLeftRadius={10} borderBottomRightRadius={10}  >
            <ReactPlayer url={link} controls={true} loop={true} playing={true} width={"100%"} style={{ zIndex: "2" }} height="100%" />
            {/* test url: https://youtu.be/m2iCiUrR4Wk */}
          </Flex>
          {/* </ModalHeader> */}
          <ModalBody className="overflow-auto" bg={'white'} borderBottomLeftRadius={10} borderBottomRightRadius={10}>
            <Text p={2} className="custom-font-heading" fontWeight={"bold"} fontSize={"30px"} textAlign='center'>{heading}</Text>
            <Flex justifySelf={'center'} className="custom-font-description" style={{ textIndent: "15px" }} fontSize={18} m={5} textAlign={'justify'} >
              {description}
            </Flex>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  )
}
export default Video;