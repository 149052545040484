import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { server, uri } from '../../config';
import {
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import CreateProductData from 'components/CommonFunctions/DBCalls/CreateProductData';
import ReadSingleProduct from 'components/CommonFunctions/DBCalls/ReadSingleProduct';
import UpdateProductData from 'components/CommonFunctions/DBCalls/UpdateProductData';
import {
  Flex, FormControl, Stack, FormLabel, Button, Input,Text, Heading, Spacer, Textarea, Modal, Checkbox,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton, useDisclosure
} from '@chakra-ui/react';
import Swal from 'sweetalert2'
import Card from 'components/Card/Card';

const MasterFile = () => {
  // console.log(prop.id);
  const { id } = useParams();
  //   let id;
  //  prop.id &&  (id  = prop.id);
  const [productIds, setProductId] = useState('');
  const [productNames, setProductName] = useState('');
  const [productBrands, setProductBrand] = useState('');
  const [productDescpt, setProductDesc] = useState('');
  const [ModalStatus, setModalStatus] = useState(false);
  const [imageLink, setImageLink] = useState('');
  const [processList, setProcessList] = useState([{ processName: "", description: "", videoLink: "", imageLink: "" }]);
  const [productList, setProductList] = useState([{ relatedproductName: "", hyperLink: "", imageLink: "" ,color:""}]);

  const processInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...processList];
    list[index][name] = value;
    // console.log(list)
    setProcessList(list);
  };
  // handle click event of the Remove button
  const processRemoveClick = index => {
    const list = [...processList];
    list.splice(index, 1);
    setProcessList(list);
  };
  // handle click event of the Add button
  const processAddClick = () => {
    setProcessList([...processList, { processName: "",description:"", videoLink: "", imageLink: "" }]);
  };
  // ---------------------------related--------------------------------
  const relatedInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...productList];
    list[index][name] = value;
    // console.log(list)
    setProductList(list);
  };
  // handle click event of the Remove button
  const relatedRemoveClick = index => {
    const list = [...productList];
    list.splice(index, 1);
    setProductList(list);
  };
  // handle click event of the Add button
  const relatedAddClick = () => {
    setProductList([...productList, { relatedproductName: "", hyperLink: "", imageLink: "",color:"" }]);
  };
  // ---------------------------related----------------------------------------------------------------

  useEffect(() => {
    const getProduct = async () => {
      let pid = id ? id : 0;
      if (pid) {
        setModalStatus(true);
      }
      const productServer = await ReadSingleProduct(pid);
      // setProduct(productServer);
      // console.table(productServer)
      setProductId(productServer.productId);
      setProductName(productServer.productName);
      setProductBrand(productServer.productBrand);
      setImageLink(productServer.imageLink);
      setProductDesc(productServer.productDesc);
      if ((productServer.processList).length > 0) {
        setProcessList(productServer.processList);
      }
      if ((productServer.relatedProduct).length > 0) {
        setProductList(productServer.relatedProduct);
      }
     
    };
    id && getProduct();
  }, [id])

  const OnUpdate = (e) => {
   
    if (!productIds) {
      alert('Please add a Product Id');
      return;
    }
    if (!productNames) {
      alert('Please add a Product Name');
      return;
    }
    if (!productDescpt) {
      return;
    }

    // Update Product
    let udata = UpdateProductData(productIds, productNames, productBrands, productDescpt,imageLink, processList, productList);

    if(udata){
      Swal.fire(
        'Product Updated Successfully!').then((result)=>{
          window.location.href = `${uri}/admin/productList`
        })
     }else{
      Swal.fire(
        'Error : Updation Failed!'
      ).then((result)=>{
        window.location.href = `${uri}/admin/productList`
      })
     }
     handleClose();
  }
  const onSubmit =async (e) => {
    e.preventDefault();
    if (!productIds) {
      alert('Please add a Product Id');
      return;
    }
    if (!productNames) {
      alert('Please add a Product Name');
      return;
    }
    if (!productDescpt) {
      alert('Please add a Product Description');
      return;
    }
   
    let rdata =await CreateProductData(productIds, productNames, productBrands, productDescpt,imageLink, processList, productList);
    if(rdata){
      Swal.fire('Product Added Successfully!')
     }else{
      Swal.fire('Error : Already Exists Product Id!')
     }
     handleClose();
  };
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClick = () => {
    setModalStatus(true);
  }
  const handleClose = () => {
    setModalStatus(false);
    id? window.location.pathname = `/admin/productList` :   navigate("" + location.search)

  }
 
  return (
    <>
      {id ? "" : <Button onClick={handleClick} m={5} class='bn632-hover bn26' w={{ base: '100%', md: '25%' }} > Add Product</Button>}
      <Modal
        
        isOpen={ModalStatus}
        onClose={handleClose}
        size={'3xl'}
      >
        <ModalOverlay />
        <ModalContent bg={'#CCD1E4'}>
          <ModalHeader>Add Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody  mb={14}>
            <Flex align={'center'}
              justify={'center'} >
              <Card justifyContent={'center'} bg={'linear-gradient(315deg, #1b2845 0%, #274060 74%)'}>
                <Stack py={1} px={1} justifyContent={'center'}>
                  <Stack>
                    <form onSubmit={onSubmit}>
                      <Flex alignItems="center" justifyContent="start">
                        <Flex
                          direction={{ base: 'column', md: 'column' }}
                          w="100%"
                          background="transparent"
                          p={{ base: '8px', md: '28px', xl: '0' }}
                        >
                          <Flex
                            direction={{ base: 'column', md: 'row' }}
                            w="100%"
                            justifyContent={"space-between"}
                            background="transparent"
                            marginBottom={"4"}
                          >
                            <FormControl variant='floating' id='productIds' mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired>
                              <Input  bg={"blackAlpha.100"} placeholder="Id"
                                focusBorderColor={'transparent'}
                                type = "number"
                                id="productIds"
                                color={'white'}
                               
                                value={productIds}
                                onChange={(e) => setProductId(e.target.value)} />
                              <FormLabel>Id</FormLabel>
                            </FormControl>
                            <Spacer />
                            <FormControl variant='floating' id='productNames' mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired>
                              <Input
                                type="text"
                                bg={"blackAlpha.100"}
                                focusBorderColor={'transparent'}
                                id="productNames"
                                color={'white'}
                                placeholder="Name"
                                value={productNames}
                                onChange={(e) => setProductName(e.target.value)}
                              // required
                              />
                              <FormLabel> Name</FormLabel>
                            </FormControl>
                            <Spacer />
                             <FormControl variant='floating' id='productBrands' mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired>
                              <Input
                                type="text"
                                bg={"blackAlpha.100"}
                                focusBorderColor={'transparent'}
                                id="productBrands"
                                placeholder="Brand"
                                color={'white'}
                                value={productBrands}
                                onChange={(e) => setProductBrand(e.target.value)}
                              // required
                              />
                              <FormLabel>Brand</FormLabel>
                            </FormControl>
                          </Flex>
                          <Flex
                            direction={{ base: 'column', md: 'row' }}
                            w="100%"
                            justifyContent={"space-between"}
                            background="transparent"
                            marginBottom={"4"}
                          >
                            <FormControl variant='floating' id='productDescpt' mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired>
                              <Textarea
                                placeholder="Description"
                                bg={"blackAlpha.100"}
                                focusBorderColor={'transparent'}
                                id="productDescpt"
                                color={'white'}
                                value={productDescpt}
                                onChange={(e) => setProductDesc(e.target.value)}
                              // required
                              />
                              <FormLabel>
                                Description
                              </FormLabel>
                            </FormControl>
                            <FormControl variant='floating' id="imageLink" mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired >
                              <Input type="url" bg={"blackAlpha.100"} color={'white'} name="imageLink" focusBorderColor={'transparent'} placeholder="Image Link"
                                value={imageLink} onChange={e => setImageLink(e.target.value)} />
                              <FormLabel> Image Link</FormLabel>
                            </FormControl>
                            <Spacer />

                          </Flex>
                          

                          <Flex
                            direction={{ base: 'column', md: 'column' }}
                            w="100%"
                            justifyContent={"space-between"}
                            background="transparent"
                            marginBottom={"4"}
                            border={'lightcyan'}
                            borderStyle={'groove'}
                            p={5}
                          >
                            {processList.map((x, i) => {
                              return (
                                <>
                                  <Flex
                                    direction={{ base: 'column', md: 'row' }}
                                    w="100%"
                                    justifyContent={"space-between"}
                                    background="transparent"
                                    mb={4}
                                  >
                                    <FormControl variant='floating' id="processName" mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired >
                                      <Input type="text" bg={"blackAlpha.100"} color={'white'} name="processName" focusBorderColor={'transparent'} placeholder="Process Name"
                                        value={x.processName} onChange={e => processInputChange(e, i)} />
                                      <FormLabel> Process Name</FormLabel>
                                    </FormControl>
                                    <FormControl variant='floating' id="description" mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired >
                                      <Textarea type="text" bg={"blackAlpha.100"} color={'white'} name="description" focusBorderColor={'transparent'} placeholder="Description"
                                        value={x.description} onChange={e => processInputChange(e, i)} />
                                      <FormLabel> Description</FormLabel>
                                    </FormControl>
                                  </Flex>
                                  <Flex
                                    direction={{ base: 'column', md: 'row' }}
                                    w="100%"
                                    justifyContent={"space-between"}
                                    background="transparent"
                                    marginBottom={"4"}
                                  >

                                    <FormControl variant='floating' id="videoLink" mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired >
                                      <Input type="url" bg={"blackAlpha.100"} color={'white'} name="videoLink" focusBorderColor={'transparent'} placeholder="Video Link"
                                        value={x.videoLink} onChange={e => processInputChange(e, i)} />
                                      <FormLabel> Video Link</FormLabel>
                                    </FormControl>
                                    <FormControl variant='floating' id="imageLink" mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired >
                                      <Input type="url" bg={"blackAlpha.100"} color={'white'} name="imageLink" focusBorderColor={'transparent'} placeholder="Image Link"
                                        value={x.imageLink} onChange={e => processInputChange(e, i)} />
                                      <FormLabel> Image Link</FormLabel>
                                    </FormControl>
                                    {processList.length !== 1 && <Button
                                      onClick={() => processRemoveClick(i)} class='bn632-hover bn26' w={{ base: '100%', md: '25%' }} style={{ marginRight: '10px' }}>Remove</Button>}
                                    {processList.length - 1 === i && <Button class='bn632-hover bn26' w={{ base: '100%', md: '25%' }} onClick={processAddClick}>Add</Button>}
                                  </Flex>
                                </>
                              );
                            })}
                          </Flex>

 {/* ------------------------------------------------------related product---------------------------------- */}
                         <Flex
                            direction={{ base: 'column', md: 'column' }}
                            w="100%"
                            justifyContent={"space-between"}
                            background="transparent"
                            marginBottom={"4"}
                           
                            borderStyle={'groove'}
                            p={5}
                          >
                            <Text
                            fontWeight={'bold'}
                            fontSize={'large'}
                            pb={5}
                            >Related Products</Text>
                            {productList.map((x, i) => {
                              return (
                                <>
                                  <Flex
                                    direction={{ base: 'column', md: 'row' }}
                                    w="100%"
                                    justifyContent={"space-between"}
                                    background="transparent"
                                    mb={4}
                                  >
                                    <FormControl variant='floating' id="relatedproductName" mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired >
                                      <Input type="text" bg={"blackAlpha.100"} color={'white'} name="relatedproductName" focusBorderColor={'transparent'} placeholder="Related Product Name"
                                        value={x.relatedproductName} onChange={e => relatedInputChange(e, i)} />
                                      <FormLabel> Related Product Name</FormLabel>
                                    </FormControl>
                                    
                                  </Flex>
                                  <Flex
                                    direction={{ base: 'column', md: 'row' }}
                                    w="100%"
                                    justifyContent={"space-between"}
                                    background="transparent"
                                    marginBottom={"4"}
                                  >

                                    <FormControl variant='floating' id="hyperLink" mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired >
                                      <Input type="url" bg={"blackAlpha.100"} color={'white'} name="hyperLink" focusBorderColor={'transparent'} placeholder="Product Link"
                                        value={x.hyperLink} onChange={e => relatedInputChange(e, i)} />
                                      <FormLabel> Product Link</FormLabel>
                                    </FormControl>
                                    <FormControl variant='floating' id="imageLink" mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired >
                                      <Input type="url" bg={"blackAlpha.100"} color={'white'} name="imageLink" focusBorderColor={'transparent'} placeholder="Product Image Link"
                                        value={x.imageLink} onChange={e => relatedInputChange(e, i)} />
                                      <FormLabel> Product Image Link</FormLabel>
                                    </FormControl>
                                    <FormControl variant='floating' id="color" mr={3} mb={{ base: '6px', md: '0', xl: '0' }} isRequired >
                                      <Input type="url" bg={"blackAlpha.100"} color={'white'} name="color" focusBorderColor={'transparent'} placeholder="Product Label Color"
                                        value={x.color} onChange={e => relatedInputChange(e, i)} />
                                      <FormLabel> Product Label Color</FormLabel>
                                    </FormControl>
                                    {productList.length !== 1 && <Button
                                      onClick={() => relatedRemoveClick(i)} class='bn632-hover bn26' w={{ base: '100%', md: '25%' }} style={{ marginRight: '10px' }}>Remove</Button>}
                                    {productList.length - 1 === i && <Button class='bn632-hover bn26' w={{ base: '100%', md: '25%' }} onClick={relatedAddClick}>Add</Button>}
                                  </Flex>
                                </>
                              );
                            })}
                          </Flex>
 {/* --------------------------relted product------------------------------------ */}


                          <Flex
                            direction={{ base: 'column', md: 'row' }}
                            w="100%"
                            justifyContent={"space-between"}
                            background="transparent"
                            marginBottom={"4"}
                          >
                          </Flex>
                          {!id ? <Button type="submit" class='bn632-hover bn26' w={{ base: '100%', md: '30%' }} m={3}>
                            Add
                          </Button> :
                            <Button type="button" onClick={OnUpdate} class='bn632-hover bn26' w={{ base: '100%', md: '30%' }} m={3}>
                              Update
                            </Button>}
                        </Flex>
                      </Flex>
                    </form>

                  </Stack>
                </Stack>
              </Card>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MasterFile;