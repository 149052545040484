import axios from 'axios';
import React, { useState } from 'react';
import {
    Flex, FormControl, FormLabel, Button, Input, Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalHeader,
    ModalCloseButton, useDisclosure,
    FormHelperText
} from '@chakra-ui/react';
import Swal from 'sweetalert2';
import Card from 'components/Card/Card';
import handleChangePassword from 'components/CommonFunctions/DBCalls/handleChangePassword';
const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [ModalStatus, setModalStatus] = useState(false);
    const [errors, setErrors] = useState({ password: "", newpassword: "", repeatpassword: "" });
    const [repeatPassword, setRepeatPassword] = useState('');
    const initialRef = React.useRef()
    const handlePasswordSubmit = async () => {
        if (!oldPassword) {
            setErrors({ password: 'Enter Current Password ' })
        }
        else if (!newPassword) {
            setErrors({ newpassword: 'Enter New Password' })
        }
        else if (!repeatPassword) {
            setErrors({ repeatpassword: 'Enter Confirm Password' })
        }
        else if (newPassword != repeatPassword) {
            setErrors({ repeatpassword: "password don't match" });
        }
        else {

            const data = await handleChangePassword(oldPassword, newPassword);

            if (data == true) {
                Swal.fire(
                    'Password Updated Successfully '
                )
            } else {
                Swal.fire(
                    'Password Update Failed '
                )

            }
            handleClose();
        }
    }
    const handleClick = () => {
        setModalStatus(true);
    }
    const handleClose = () => {
        setModalStatus(false);
    }
    return (
        <>
            <Button onClick={handleClick} bg={'transparent'} color={'white'} _hover={{ bg: "gray" }}>Change Password</Button>
            <Modal
                initialFocusRef={initialRef}
                isOpen={ModalStatus}
                onClose={handleClose}
                size={'2xl'}
            >
                <ModalOverlay />
                <ModalContent bg={'#CCD1E4'}>
                    <ModalHeader>Change Password</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mb={10} borderRadius={10}>
                        <Card justifyContent={'center'} bg={'linear-gradient(315deg, #1b2845 0%, #274060 74%)'}>
                            <FormControl variant='floating' isRequired>
                                <Input ref={initialRef} placeholder='Current Password' value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)} boxShadow={'none'}  isRequired/>
                                <FormLabel>Current Password</FormLabel>
                                <FormHelperText color="red" className="error2" alignSelf={"center"} mt={3} >
                                    {errors && errors.password}
                                </FormHelperText>
                            </FormControl>
                            <FormControl mt={8} variant='floating' isRequired>
                                <Input placeholder='New Password' value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}  isRequired/>
                                <FormLabel>New Password</FormLabel>
                                <FormHelperText color="red" className="error2" alignSelf={"center"} mt={3} >
                                    {errors && errors.newpassword}
                                </FormHelperText>
                            </FormControl>
                            <FormControl mt={8} variant='floating' isRequired>
                                <Input placeholder='Confirm Password' value={repeatPassword}
                                    onChange={(e) => setRepeatPassword(e.target.value)}  isRequired/>
                                <FormLabel>Confirm Password</FormLabel>
                                {errors && (
                                    <FormHelperText color="red" className="error2" alignSelf={"center"} mt={3} >
                                        {errors && errors.repeatpassword}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Flex direction={'row'} mr={3} mt={8}>
                                <Button class='bn632-hover bn26' onClick={handlePasswordSubmit}>
                                    Save
                                </Button>
                            </Flex>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ChangePassword;