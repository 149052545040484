import { Flex, Image, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import React from "react";
import logo from "../../../../assets/new/1.png";
import process1 from "../../../../assets/new/2.jpeg";
import leftArrow from "../../../../assets/LeftArrowBlue.png";
import RightArrow from "../../../../assets/RightArrowBlue.png";
import Video from "components/MainPage/VideoPopUp/Videos";
export default function Process(props) {
  let product = props.product;
  let Arrow = product.processList.length - 1;
  return (
    <>
      {product.processList && (
        <Flex direction={"column"} pl={5} mt={8} display={{ lg: "none", xl: "none" }}>
          <Flex>
            <Image src={logo} alt="Process" />
            <Text
              fontWeight="700"
              fontSize="30px"
              lineHeight="133.69%"
              textTransform="uppercase"
              className='custom-font-heading'
              data-aos="fade-down"
              data-aos-delay="300"
              data-aos-duration="300"
              data-aos-easing="linear"
            >
              Process
            </Text>
          </Flex>
          {product.processList.map((process, i) => (
            <>
              {i % 2 == 0 ? (
                <>
                  <Flex
                    mt={5}
                    alignContent="center"
                    data-aos="zoom-in"
                    data-aos-duration="3000"
                    data-aos-delay="300"
                    overflow={"hidden"}
                    filter="drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.63))"
                  >
                    <Card
                      width="95%"
                      height="130px"
                      p={2}
                     background="linear-gradient(232.34deg, #CCD5D8  0.52%,#0F83A3  99.48%)"
                      borderRadius="15px"
                    >
                      <Flex
                        width="100%"
                        direction={"row"}
                        justifyContent="center"
                        height="100%"
                        background="#3FC1E5"
                        borderRadius="15px"
                        boxShadow={"inset 0px 4px 4px rgba(0, 0, 0, 0.25)"}
                      >
                        <Flex direction={"column"} width="60%">
                          <Text
                            fontWeight="600"
                            fontSize="22px"
                            lineHeight="100%"
                            textTransform="uppercase"
                            color="#FFFDFD"
                            textAlign={"center"}
                            mt={3}
                            className="custom-font-subheading"
                          >
                            {process.processName}
                          </Text>

                          <Flex>
                            <Video link={process.videoLink} />
                          </Flex>

                        </Flex>
                        <Flex width="40%">
                          <Image
                            src={process.imageLink}
                            alt="process1"
                            width={"100%"}
                            height="100%"
                            borderBottomRightRadius={15}
                            borderTopRightRadius={15}
                          />
                        </Flex>
                      </Flex>
                    </Card>
                  </Flex>
                  {i != Arrow ? (
                    <Flex
                      data-aos="fade-down"
                      data-aos-duration="3000"
                      data-aos-delay="300"
                      mt={5}
                    >
                      <Image
                        src={leftArrow}
                        w={"30%"}
                        ms={"35%"}
                        style={{ transform: "rotate(-23deg)" }}
                      />
                    </Flex>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <Flex
                    mt={5}
                    alignContent="center"
                    data-aos="zoom-in"
                    data-aos-duration="3000"
                    overflow={"hidden"}
                    filter="drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.63))"
                  >
                    <Card
                      width="95%"
                      height="125px"
                      p={2}
                      background="linear-gradient(232.34deg,  #0F83A3 0.52%,#CCD5D8  99.48%)"
                      borderRadius="15px"
                    >
                      <Flex
                        width="100%"
                        direction={"row"}
                        justifyContent="center"
                        height="100%"
                        background="#3FC1E5"
                        borderRadius="15px"
                        boxShadow={"inset 0px 4px 4px rgba(0, 0, 0, 0.25)"}
                      >
                        <Flex width="40%">
                          <Image
                            src={process.imageLink}
                            alt="process1"
                            width={"100%"}
                            height="100%"
                            borderBottomLeftRadius={15}
                            borderTopLeftRadius={15}
                          />
                        </Flex>
                        <Flex direction={"column"} width="60%">
                          <Text
                            fontWeight="600"
                            fontSize="26px"
                            lineHeight="100%"
                            textTransform="uppercase"
                            color="#FFFDFD"
                            textAlign={"center"}
                            className="custom-font-subheading"
                            mt={3}
                          >
                            {process.processName}
                          </Text>
                          <Flex ml={"20px"}>

                            <Video link={process.videoLink} />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Card>
                  </Flex>
                  {i != Arrow ? (
                    <Flex
                      data-aos="fade-down"
                      data-aos-duration="3000"
                      data-aos-delay="300"
                      mt={5}
                    >
                      <Image
                        src={RightArrow}
                        w={"30%"}
                        ms={"20%"}
                        style={{ transform: "rotate(15deg)" }}
                      />
                    </Flex>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          ))}
        </Flex>
      )}
    </>
  );
}
