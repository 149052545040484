import { mode } from "@chakra-ui/theme-tools";
export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
    },
  },
  styles: {
    global: (props) => ({
      body: {


        // bg: mode("linear-gradient(130deg, #0b8793 0%,#360033 100%)","linear-gradient(310deg, #142850 24%,  #27496D 51%, #0C7B93 78%,#00A8CC 91%)")(props),
        // bgOpacity:0.5,

        // bgcolor: "#130f40",
        // bg: " linear-gradient(315deg, #1b2845 -40%, #274060 4%)",
        bgcolor: "#bdd4e7",
bg: "linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%)",

        // linear-gradient(315deg, #1b2845 0%, #274060 74%)
        bgFilter: 'brightness(50%)',
        fontFamily: 'Helvetica, sans-serif',
        backgroundBlendMode: 'screen'

      },
      html: {
        fontFamily: 'Helvetica, sans-serif',
        minHeight: '100%',
        maxHeight: 'auto'
      }
    }),
  },
};
