/** @format */

import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import logo from "../../../../assets/new/1.png";
import lighten from "../../../../assets/new/lightening2.gif";
import flower from "../../../../assets/new/3.png";
import hatman from "../../../../assets/new/berwer.png";
import "./brewer.css";
import Video from "./VideoPopUp/Videos";

let brewLogo = "https://res.cloudinary.com/dj6eghdzu/image/upload/v1652815783/Brewtoon/Scuffed_Black_voirs5.png"

export default function BreweryInfo() {
  return (
    <Flex display={{ xl: "none", lg: "none", '2xl': "none", md: "none" }}>
      <Flex direction={"column"} mt={8} >
        <Flex pl={5}>
          <Image src={logo} alt="Process" />
          <Text
            fontWeight="700"
            fontSize="30px"
            lineHeight="133.69%"
            textTransform="uppercase"
            className='custom-font-heading'
            data-aos="fade-down"
            data-aos-delay="300"
            data-aos-duration="300"
            data-aos-easing="linear"
          >
            Brewery Info
          </Text>
        </Flex>
        <Flex
          direction={"column"}
          width={"90%"}
          height="100%"
          alignSelf={"center"}
          mt={6}
          position="relative"
        >
          <Flex height={"100%"} borderRadius="10px 10px 0px 0px">
            <Image
              borderRadius="10px 10px 0px 0px"
              height={"50px"}
              width={"100%"}
              src={lighten}
              alt="minal"
            />
          </Flex>
          <span className="dot" id="dot1"></span>
          <span className="dot" id="dot2">
            <Image
              alignSelf={"center"}
              width={"50%"}
              hight={"50%"}
              ml={8}
              mt="2"
              src={brewLogo}
              className="brewAnime"
              alt="thoppykaran"
            />
          </span>
          <Flex
            height={"80%"}
            p={6}
            direction={"column"}
            pt={16}
            background={`linear-gradient(180deg, rgba(17, 202, 255, 0.2) 59.79%, rgba(255, 255, 255, 0.2) 92.77%), url(${flower})`}
            bgRepeat="no-repeat"
            bgSize={"cover"}
          >
            <Text
              fontSize="22px"
              fontWeight="500"
              lineHeight="30px"
              className="custom-font-description"
              textAlign="justify"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="2000"
            >
              Established in 2017 in the most Eastern point of mainland Scotland,
              we brew our beers in small batches in the nautical town of
              Peterhead. At Brew Toon we are passionate about creating bold,
              adventurous craft beers, packed full of flavour and character. We
              strive to use local ingredients where ever possible and pride
              ourselves on this provenance.
              <br />
              <br />
            </Text>
            <Video
              link={"https://www.youtube.com/watch?v=NzDpdd0Ml_M"}
              heading={"Brewery Info"}
              description={`Brew Toon was born out of a passion for brewing and craftsmanship in general. 
                        Our brewery is based on a historic site in Peterhead, which was once home to "Hunter & Sons - Tanfield Brewery". This heritage is hugely important to us and massively influential in how we run our brewery today.`}
              background="linear-gradient(90.12deg, rgba(2, 148, 187, 0.748841) 8.92%, rgba(0, 146, 185, 0.78) 15.63%, rgba(34, 171, 208, 0.3198) 105.45%);"
            />
          </Flex>
          <Flex height={"10%"}>
            <Image height={"30px"} width={"100%"} src={lighten} alt="minal" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
