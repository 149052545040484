import { Alert } from '@chakra-ui/react';
import axios from 'axios';
import { server } from '../../../config';
import Swal from 'sweetalert2'
import { truncate } from 'lodash';
async function CreateProductData(Id,Name,Brand,Desc,imageLink,processList,relatedProduct){
    let productId = Id.trim();
    let productName = Name.toLowerCase().trim();
    let productBrand = Brand.toLowerCase().trim();
    let productDesc = Desc.trim();
    
    const data = await axios
        .post(`${server}/api/products/add`, {
          productId,
          productName,
          productBrand,
          productDesc,
          imageLink,
          processList,
          relatedProduct
        }
        // }, {
        //   headers: { 'x-access-token': logDetails.token },
        // }
        )
        .then((res) => {
          if(res.status==200){
         
            return true;
            }
            else  if(res.status==203){
              return false;
            }
        })
        .catch((err) =>  alert("Error Adding This Id/Already Exists"));
      const product = data;
      // console.log(product);
      return product;
    }


export default CreateProductData;