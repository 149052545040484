import {
    Flex, Table,
    Text,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import Card from '../../components/Card/Card';
import axios from 'axios';
import { server } from 'config';
export default function ProductAnalysis() {
    const [moreProduct, setMoreProduct] = useState([]);
    useEffect(() => {

     getMoreProductAnalysis()

    }, [])
    async function getMoreProductAnalysis() {
        let data = await axios
            .get(`${server}/api/analysis`)
            .then((res) => {
                setMoreProduct(res.data);
                return res.data;

            })
            .catch((err) => console.error(err));
        return data;
    };
   
    return (
        <Flex>
            <Card>
                <Text
                    color={'white'}
                    textShadow={'0px 4px 8px rgba(0, 0, 0, 0.5)'}
                    letterSpacing={1}
                    fontSize='3xl'
                    textTransform={'uppercase'}
                    fontWeight='extrabold'
                    mb={10} > Most Clicked Products Analysis Report </Text>
              {(moreProduct.length > 0) ? <TableContainer border={'1px solid #d2ccc4'} shadow={"0 0 3px 1px rgba(0, 0, 0, 0.25)"} borderRadius={12} overflow="hidden">
                    <Table size='md' >
                        <Thead p={3}>
                            <Tr>
                                <Th>Product Name</Th>
                                <Th>Count</Th>
                                <Th>Location</Th>
                                <Th>Date</Th>
                            </Tr>
                        </Thead>
                        {moreProduct.map((product) => (
                            <Tbody>
                                <Tr>
                                    <Td>{product.productName}</Td>
                                    <Td>{product.productCount}</Td>
                                    <Td>
                                        {product.details.map((loc) => (
                                            <>
                                                <Tr>
                                                    {loc.location.cityCountry}
                                                </Tr>
                                            </>
                                        ))}
                                    </Td>
                                    <Td>
                                        {product.details.map((loc) => (
                                            <>
                                                <Tr>
                                                    {
                                                        loc.date.slice(8, 10) + '/'
                                                        + loc.date.slice(5, 7) + '/'
                                                        + loc.date.slice(0, 4)}
                                                </Tr>
                                            </>
                                        ))}
                                    </Td>

                                </Tr>
                            </Tbody>
                        ))}
                    </Table>
                </TableContainer> :"No Data Available"}
            </Card>
        </Flex>
    )
}
