import axios from 'axios';
import { server } from '../../../config';
import Swal from 'sweetalert2';
async function handleChangePassword(oldPassword, newPassword) {
  const logData = localStorage.getItem('tg-token');
  let logDetails = JSON.parse(logData);
  const data = await axios
    .put(`${server}/api/users/changepassword`, {
      oldPassword,
      newPassword
    }
      , {
        headers: { 'x-access-token': logDetails.token },
      }
    )
    .then((res) => {
      if (res.status == 200) {
        localStorage.setItem(
          "tg-token",
          JSON.stringify({
            token: res.data.msg,
            userName: logDetails.userName,
          })
        );
        return true;
      }
      else if (res.status == 400) {
        return false;
      }
    })
    .catch((err) => { return "Error Password change failed" }
    );
  const product = await data;
  // console.log(product);
  return product;
}


export default handleChangePassword;