/** @format */

import {
  Flex,
  Text,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  ModalCloseButton,
} from "@chakra-ui/react";
import React from "react";
import "../../NewHeader/Header3d.scss";

let desImg =
  "https://res.cloudinary.com/dj6eghdzu/image/upload/v1652815783/Brewtoon/Scuffed_Black_Text_brwga8.png";
let logoImg =
  "https://res.cloudinary.com/dj6eghdzu/image/upload/v1652875903/Brewtoon/Scuffed_Black_1_uwijg4.png";

const Description = (prop) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let proDescription = prop.product;
  let description = proDescription.productDesc;
  return (
    <Flex display={{ xl: "none", lg: "none", "2xl": "none", md: "none" }}>
      <Flex
        background={`
    url(${logoImg})`}
        bgPosition="center"
        bgSize={"300px"}
        bgRepeat="no-repeat"
        w="100%"
        justifyContent={"center"}
        alignItems="center"
        mt="60px"

        // className="animate__animated animate__zoomIn"
      >
        <Flex
          direction={"column"}
          w={"90%"}
          h="408px"
          background={"linear-gradient(90.12deg, rgba(2, 148, 187, 0.748841) 8.92%, rgba(0, 146, 185, 0.78) 15.63%, rgba(34, 171, 208, 0.3198) 105.45%)"}
          boxShadow={"inset 0px 4px 4px rgba(0, 0, 0, 0.29)"}
          borderRadius="10px"
          justifyContent={"center"}

          // alignItems="center"
          // alignSelf={"center"}
        >
          <Flex
            w={"32%"}
            ml="55%"
            mt={"-92%"}
            position={"absolute"}
            // top="11.9%"
            display={{ lg: "none", xl: "none", md: "none" }}
            data-aos="fade-left"
            data-aos-delay="800"
            data-aos-duration="1000"
            overflow={"hidden"}
          >
            <Image src={desImg} opacity="0.7"/>
          </Flex>
          <Text
            className="custom-font-heading text-uppercase"
            marginTop={"-10px"}
            fontSize="xx-large"
            textAlign="left"
            marginLeft={5}
            fontWeight="bold"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
            data-aos-delay="1200"
            // data-aos="fade-right"
            // data-aos-delay="1200"
            // data-aos-duration="600"
            // data-aos-easing="ease-in-sine"
          >
            DESCRIPTION
          </Text>
          <Flex className="typing">
            <Flex className="text-cover">
              <Text
                textAlign={"justify"}
                className="custom-font-description "
                Width={"50px"}
                // whiteSpace="nowrap"
                overflow={"hidden"}
                textOverflow="ellipsis"
                noOfLines={9}
                pl={6}
                pr={6}
                pt={"50px"}
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-delay="1200"
                data-aos-duration="1800"
              >
                {description}
              </Text>
            </Flex>
          </Flex>
          <Flex
            mt="1"
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-duration="600"
            data-aos-easing="ease-in-sine"
          >
            <Button
              // background="linear-gradient(90.12deg, rgba(2, 148, 187, 0.748841) 8.92%, rgba(0, 146, 185, 0.78) 15.63%, rgba(34, 171, 208, 0.3198) 105.45%);"
              class="btn-grad2 custom-font-description"
              borderRadius="15px"
              // textAlign={"center"}
              // pl={"2px"}
              // ml="20px"
              // justifyContent={"center"}
              pr={2}
              // height="30px"
              // width={"100px"}
              color={"#000"}
              fontWeight={"bold"}
              cursor="pointer"
              borderBottom={"1px solid blue"}
              fontSize={"14px"}
              onClick={onOpen}
            >
              Read more
            </Button>
            {/* modal part */}
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              isCentered
              allowPinchZoom={true}
              overflow={"auto"}
            >
              <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="auto"
                backdropInvert="25%"
                backdropBlur="4px"
              />
              <ModalContent>
                <ModalHeader className="custom-font-heading" fontSize={"30px"}>
                  {" "}
                  DESCRIPTION{" "}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody
                  className="overflow-auto custom-font-description"
                  bg={"white"}
                  borderBottomLeftRadius={20}
                  borderBottomRightRadius={20}
                  fontSize={"15px"}
                >
                  {/* <Lorem count={2} /> */}
                  <Flex textAlign={"justify"} mt={5}>
                    {description}
                  </Flex>
                </ModalBody>

                <ModalFooter>
                  <Button
                    background="linear-gradient(90.12deg, rgba(2, 148, 187, 0.748841) 8.92%, rgba(0, 146, 185, 0.78) 15.63%, rgba(34, 171, 208, 0.3198) 105.45%);"
                    mr={3}
                    onClick={onClose}
                  >
                    Close
                  </Button>
                  {/* <Button variant="ghost">Secondary Action</Button> */}
                </ModalFooter>
              </ModalContent>
            </Modal>
            {/* modal part end */}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Description;
