const CardHeader = {
  baseStyle: {
    display: "flex",
    width: "90%"
  },
};

export const CardHeaderComponent = {
  components: {
    CardHeader,
  },
};
