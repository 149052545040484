import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Doughnut, Pie } from "react-chartjs-2";
import { chartColors } from "./colors";
import "./MainPage.css";

function PieCharts({moreProducts}) {
  let chartInstance = null;
  let [moreProduct,setMoreProduct]=useState(moreProducts)
  const options = {
    legend: {
      display: true,
      position: "bottom",
    
    }
  };
  
  const pieOptions = {
    legend: {
      display: false,
      position: "left",

    }
  };

  // console.log(moreProduct[0].productName,moreProduct[0].productCount)
  let data,label=[],prodData=[],i=0;
  {moreProduct && moreProduct.map((prod,i)=>(
    // {moreProduct.length>0  && moreProduct.forEach(prod => {
  
    label.push(prod.productName),
    prodData.push(prod.productCount)
    // console.log(label +"hai");
  ))}
 
    data = {
        maintainAspectRatio: false,
        responsive: true,
        labels:label,
        datasets: [
          {
            // labels: label,
            data: prodData,
            backgroundColor: chartColors,
            hoverBackgroundColor: chartColors
          }
        ]
      }
  
 
  

  return (
    
      <div style={styles.relative}>
        <Doughnut data={data} options={options}  width={300}
        height={300} />
        {/* <div style={styles.pieContainer}>
          <Pie
            data={data}
            options={pieOptions}
            ref={input => {
              chartInstance = input;
            }}
          
          />
        </div> */}
        <div id="legend" />
      </div>
  
  );
}

const styles = {
  pieContainer: {
    width: "60%",
    height: "60%",
    top: "49%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)"
  },
  relative: {
    position: "relative"
  }
};

export default PieCharts;

