import React from 'react'
import axios from 'axios';
import {server} from '../../../config';
export default async function handleMoreProduct(productName,location) {

  const data = await axios
  .post(`${server}/api/analysis/moreproduct`, {
    productName,
    location
  }
   
  )
  .then((res) => {
    if (res.status == 200) {
     
      return true;
    }
    else if (res.status == 400) {
      return false;
    }
  })
  .catch((err) => { return "Error Password change failed" }
  );
const product = await data;
// console.log(product);
return product;
}
