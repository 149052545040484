import axios from 'axios';
import { bcuri, api_key } from '../../../config';
import Swal from 'sweetalert2'

async function CreateBlockchainData(Id, inputList) {
  let data = {
    inputList
  }
  const header = {
    "content-type":
      "application/json, text/plain, application/x-www-form-urlencoded",
    headers: { Authorization: `Bearer ${api_key}` },
  };

  const serverdata = await axios
    .post(`${bcuri}/`, { Id, data }, header)
    .then((res) => {
      if (res.status === 200) {
        //if sucessfully added then return true value
        return res;
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err
    });

  let product = serverdata;
  return product;
}
export default CreateBlockchainData;