import React from 'react';
import { Text, Flex, Link, Box } from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaUntappd, FaYoutube } from "react-icons/fa";
const SocialLinks = () => {
  return (
    <>
      <Flex justifyContent={'center'} flexDirection={'column'} className="animate__animated animate__fadeInUp animate__delay-2s animate__slower" display={{ lg: "none", xl: "none" }}>
        <Text justifyContent={'center'} fontFamily='Oldenburg' color='rgba(0, 0, 0, 0.6);' mt={'10px'} textAlign={'center'}>Connect with us</Text>
        <Flex justifyContent={'center'} flexDirection={'row'} mt={4}>
          <Link textDecoration='none' className='linkedin' color='rgb(24, 7, 97)' href='https://www.linkedin.com/company/brew-toon?trk=biz-companies-cym' isExternal>
            <FaLinkedin size={'22px'} /></Link>
          <Link textDecoration='none' className='facebook' pl={3} color='#0d6efd' href='https://www.facebook.com/BrewToon/' isExternal>
            <FaFacebook size={'22px'} /></Link>
          <Link textDecoration='none' className='instagram' pl={3} color='rgb(180, 25, 90)' href='https://www.instagram.com/brew_toon/' isExternal>
            <FaInstagram size={'22px'} /></Link>
          <Link textDecoration='none' className='twitter' pl={3} color='#0d6efd' href='https://twitter.com/BrewToon' isExternal>
            <FaTwitter size={'22px'} /></Link>
          <Link textDecoration='none' className='untapped' pl={3} color='#ffc107' href='https://untappd.com/BrewToon' isExternal>
            <FaUntappd size={'22px'} /></Link>
          <Link textDecoration='none' className='youtub' pl={3} color='#dc3545' href='https://www.youtube.com/channel/UCV2czMk0n9jWP4KqBZyh_vg' isExternal>
            <FaYoutube size={'22px'} /></Link>
        </Flex>
        <Flex justifyContent={'center'} mt={2} pb="15">
          <Text color='rgba(0, 0, 0, 0.6);' >
            Powered by </Text><Text color='#11B1E3;' className='trackgen' fontFamily='Oldenburg' pl={2} > <a href='https://www.trackgenesis.com/'>TrackGenesis</a>
          </Text>
        </Flex>
      </Flex>
    </>
    // ............................
    // <section className="product p-3 pt-0 position-relative">
    // <div  className="mb-3">
    //   <h3 className="border-bottom custom-font-heading mb-3 text-uppercase">
    //     SOCIAL LINKS
    //   </h3>

    //   <div className="d-flex justify-content-around mt-4 mb-4">
    //     <a
    //       href={data.facebook} target="_blank" title="facebook"  rel="noreferrer"
    //       className="d-flex flex-column link-dark text-decoration-none d-flex align-items-center fw-bold"
    //       style={{ fontSize: '13px' }}
    //     >
    //       <FaFacebookF style={{textShadow:'1px 1px #ff0000', fontSize: '35px' }} />

    //     </a>
    //     <a
    //       href={data.instagram} target="_blank"  rel="noreferrer"
    //       className="d-flex flex-column link-dark text-decoration-none d-flex align-items-center fw-bold"
    //       style={{ fontSize: '13px' }}
    //     >
    //       <FaInstagram style={{ fontSize: '35px' }} />

    //     </a>
    //     <a
    //       href={data.twitter} target="_blank"  rel="noreferrer"
    //       className="d-flex flex-column link-dark text-decoration-none d-flex align-items-center fw-bold"
    //       style={{ fontSize: '13px' }}
    //     >
    //       <FaTwitter style={{ fontSize: '35px' }} />

    //     </a>
    //     <a
    //       href={data.untappd} target="_blank"  rel="noreferrer"
    //       className="d-flex flex-column link-dark text-decoration-none d-flex align-items-center fw-bold"
    //       style={{ fontSize: '13px' }}
    //     >
    //       <FaUntappd style={{ fontSize: '35px' }} />

    //     </a>
    //     <a
    //       href={data.youtube}target="_blank" rel="noreferrer"
    //       className="d-flex flex-column link-dark text-decoration-none d-flex align-items-center fw-bold"
    //       style={{ fontSize: '13px' }}
    //     >
    //       <FaYoutube style={{ fontSize: '35px' }} />

    //     </a>
    //     <a
    //       href={data.linkedIn} target="_blank"  rel="noreferrer"
    //       className="d-flex flex-column link-dark  text-decoration-none d-flex align-items-center fw-bold"
    //       style={{ fontSize: '13px' }}
    //     >
    //       <FaLinkedinIn style={{ fontSize: '35px' }} />

    //     </a>
    //   </div>
    // </div>


    // </section>
  );
};
export default SocialLinks;